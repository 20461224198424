import { createSelector } from '@ngrx/store';
import {
  selectAllOrganizationChildrenCodes,
  selectAllParentOrganizations,
  selectAllProfiles,
  selectGrades,
} from '../../_store/navbar-page.selectors';
import { getBodyAppointmentYear, getCurrentYear } from '../home-page.functions';
import { sample } from '../../../_shared/functions/arrays.functions';
import { getSearchRequestedProfileRows } from '../../_shared/profile-row/profile-rows.functions';

export const selectProfilesAppointedThisYear = createSelector(
  selectAllProfiles,
  (allProfiles) =>
    allProfiles
      .filter((p) => !!p.bodyDate)
      .filter((p) => getBodyAppointmentYear(p.bodyDate) === getCurrentYear())
);

export const selectProfilesRowsAppointedThisYear = createSelector(
  selectAllParentOrganizations,
  selectAllOrganizationChildrenCodes,
  selectProfilesAppointedThisYear,
  selectGrades,
  getSearchRequestedProfileRows
);

export const selectFeaturedProfileRows = createSelector(
  selectProfilesRowsAppointedThisYear,
  (profiles) => sample(profiles, 8)
);
