import { AppointmentMode } from './appointment-mode';
import { EMPTY_VALUE } from '../../../_shared/app.strings';

export function getAppointmentModeLongLabelBisByCode(
  appointmentModes: AppointmentMode[],
  code: string
): string {
  return (
    appointmentModes.find((a) => a.code === code)?.longLabelBis ?? EMPTY_VALUE
  );
}
