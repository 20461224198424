export function capitalize(s) {
  if (typeof s !== 'string') return '';
  s = s.toLocaleLowerCase();
  return s.charAt(0).toUpperCase() + s.slice(1);
}

export function removeCaseAndAccent(input: string): string {
  return input
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');
}
