import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { selectFeaturedProfileRows } from '../_store/home-page.selectors';
import { takeUntil } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ProfileRow } from '../../_shared/profile-row/profile-row';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { OWL_CAROUSEL_OPTIONS } from './owl-carousel-options';
import { selectNavbarPageIsLoading } from '../../_store/navbar-page.selectors';
import { DestroyListener } from '../../../_shared/service/widget/destroy-listener/destroy-listener';
import {
  onHomeSearchAllMagistratesInYear,
  onHomeSelectedProfileId,
} from '../_store/home-page.actions';
import { getCurrentYear } from '../home-page.functions';

@Component({
  selector: 'ama-magistrate-carousel',
  templateUrl: './magistrate-carousel.component.html',
  styleUrls: ['./magistrate-carousel.component.scss'],
})
export class MagistrateCarouselComponent extends DestroyListener
  implements OnInit {
  featuredProfiles$: Observable<ProfileRow[]>;
  isLoading: boolean;
  shadowSize = new Array(5);
  currentYear = getCurrentYear();

  customOptions: OwlOptions = OWL_CAROUSEL_OPTIONS;

  constructor(private store: Store<any>) {
    super();
  }

  ngOnInit(): void {
    this.featuredProfiles$ = this.store.pipe(
      select(selectFeaturedProfileRows),
      takeUntil(this.destroyed$)
    );
    this.store
      .pipe(select(selectNavbarPageIsLoading, takeUntil(this.destroyed$)))
      .subscribe((isLoading) => (this.isLoading = isLoading));
  }

  onSearchAllMagistrateInYear(inCarousel: boolean) {
    this.store.dispatch(
      onHomeSearchAllMagistratesInYear({ inCarousel: inCarousel })
    );
  }

  onProfileSelected(profileId: number) {
    this.store.dispatch(onHomeSelectedProfileId({ profileId }));
  }
}
