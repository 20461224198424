import { createAction, props } from '@ngrx/store';
import { SearchRequest } from '../search-request';

export const onOpenedSearchSidebar = createAction(
  '[SEARCH-SIDEBAR] onOpen search sidebar'
);
export const onClosedSearchSidebar = createAction(
  '[SEARCH-SIDEBAR] onClose search sidebar'
);
export const searchFromTemplateRequested = createAction(
  '[SEARCH-SIDEBAR] Search from template',
  props<{ searchRequest: SearchRequest }>()
);
export const onSearchRequested = createAction(
  '[SEARCH] On search Requested',
  props<{ searchRequest: SearchRequest; url: string }>()
);
