import { select, Store } from '@ngrx/store';
import { Actions, createEffect, Effect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { NavbarPageStates } from './navbar-page.states';
import {
  closeProfileSheetDialog,
  openSheetPageProfileDialog,
  organizationSelected,
  referentialsFetchRequested,
  referentialsFetchSuccess,
  restoreNavbarPageState,
  scrollToProfileId,
  startNavbarPageLoading,
  stopNavbarPageLoading,
} from './navbar-page.action';
import {
  catchError,
  filter,
  first,
  map,
  mergeMap,
  switchMap,
  tap,
} from 'rxjs/operators';
import { ProfileService } from '../_shared/profile/profile.service';
import { combineLatest, of } from 'rxjs';
import {
  backToHomeAction,
  effectError,
  logout,
  redirectAction,
} from '../../_store/ama.actions';
import { JobService } from '../_shared/job/job.service';
import { CodeManagementService } from '../_shared/code-management/code-management.service';
import { OrganizationService } from '../_shared/organization/organization.service';
import { AppointmentModeService } from '../_shared/appointment-mode/appointment-mode.service';
import { selectSelectedProfileId } from './navbar-page.selectors';
import { ScrollToService } from '../../_shared/service/widget/scroll/scroll-to.service';
import { DetachmentOrganizationService } from '../_shared/detachment-organization/detachment-organization.service';

@Injectable()
export class NavbarPageEffects {
  @Effect() restoreNavbarPageState = this.actions$.pipe(
    ofType(logout),
    map(() => restoreNavbarPageState())
  );

  @Effect() loadReferentials = this.actions$.pipe(
    ofType(referentialsFetchRequested),
    switchMap((action) =>
      combineLatest([
        this.profileService.getAll$(),
        this.organizationService.getAll$(),
        this.jobService.getAll$(),
        this.codeManagementService.findOne$(),
        this.appointmentModeService.getAll$(),
        this.detachmentOrganizationService.getAll$(),
      ]).pipe(
        map(
          ([
            profiles,
            organizations,
            jobs,
            codeManagement,
            appointmentModes,
            detachmentOrganizations,
          ]) =>
            referentialsFetchSuccess({
              profiles,
              organizations,
              jobs,
              codeManagement,
              appointmentModes,
              detachmentOrganizations,
            })
        ),
        catchError((error) => of(effectError({ error, source: action })))
      )
    )
  );

  @Effect() startNavbarLoading = this.actions$.pipe(
    ofType(referentialsFetchRequested),
    map(() => startNavbarPageLoading())
  );

  @Effect() stopNavbarLoading = this.actions$.pipe(
    ofType(referentialsFetchSuccess),
    map(() => stopNavbarPageLoading())
  );

  @Effect() openSheetPageProfileDialog$ = this.actions$.pipe(
    ofType(openSheetPageProfileDialog),
    map(() => redirectAction({ url: 'sheet' }))
  );

  @Effect() organizationSelected$ = this.actions$.pipe(
    ofType(organizationSelected, backToHomeAction),
    map(() => closeProfileSheetDialog())
  );

  scrollToProfileId$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(scrollToProfileId),
        mergeMap(() =>
          this.store.pipe(
            first(),
            select(selectSelectedProfileId),
            filter((id) => !!id),
            tap((id) => this.scrollToService.scrollToById(id))
          )
        )
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private store: Store<NavbarPageStates>,
    private profileService: ProfileService,
    private organizationService: OrganizationService,
    private jobService: JobService,
    private codeManagementService: CodeManagementService,
    private appointmentModeService: AppointmentModeService,
    private detachmentOrganizationService: DetachmentOrganizationService,
    private scrollToService: ScrollToService
  ) {}
}
