import { ProfileJob } from './profile-job';
import { Profile } from '../../../_shared/profile/profile';
import { JOB_LABEL } from '../../../_shared/job/job.strings';
import { getProfileCards } from '../profile-card/profile-card.functions';
import { getJobLabel } from '../../../_shared/job/job.functions';
import { Grade } from '../../../_shared/code-management/code-management';

export function getAllProfileJobs(
  profiles: Profile[],
  allGrades: Grade[]
): ProfileJob[] {
  return profiles
    .slice()
    .sort((a, b) => a.job.weight - b.job.weight)
    .reduce((profileJobs: ProfileJob[], profile: Profile) => {
      if (!profileJobs.find((p) => p.code === profile.job.code)) {
        profileJobs.push({
          code: profile.job.code,
          label:
            JOB_LABEL[getJobLabel(profile.job.longLabel).trim()] ??
            getJobLabel(profile.job.longLabel),
          cards: getProfileCards(
            profiles.filter((p) => p.job.code === profile.job.code),
            allGrades
          ),
        });
      }
      return profileJobs;
    }, []);
}
