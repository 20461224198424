<div class="home-page-header-bg"></div>
<div class="row no-gutters">
  <div class="col-12 search-home-form-container">
    <div class="row justify-content-between">
      <h4 class="ama-title mt-3 col-4">Rechercher un magistrat</h4>
    </div>
    <ama-search></ama-search>
  </div>
</div>
<ama-magistrate-stats></ama-magistrate-stats>
<ama-magistrate-carousel></ama-magistrate-carousel>
