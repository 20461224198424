<div class="row no-gutters carousel-title-group">
  <div class="carousel-title col-12 ml-1">
    <span>Les magistrats nommés en {{ currentYear }}</span>
    <span
      (click)="onSearchAllMagistrateInYear(false)"
      *ngIf="!isLoading"
      class="see-all-link ml-4"
    >
      Voir tout
      <mat-icon
        class="ama-icon ama-icon-small ml-2"
        svgIcon="greater_than"
      ></mat-icon>
    </span>
  </div>
</div>
<owl-carousel-o *ngIf="isLoading" [options]="customOptions">
  <ng-template *ngFor="let s of shadowSize" carouselSlide>
    <div class="ssc-wrapper row justify-content-around w-100">
      <div class="ssc-square mb-3"></div>
      <div class="ssc-head-line mb-3"></div>
    </div>
  </ng-template>
</owl-carousel-o>
<div *ngIf="!isLoading && featuredProfiles$ | async as featuredProfiles">
  <owl-carousel-o [options]="customOptions">
    <ng-template *ngFor="let profile of featuredProfiles" carouselSlide>
      <div
        (click)="onProfileSelected(profile.metadata.profileId)"
        class="carousel-card"
      >
        <div
          [style.background]="profile.metadata.bodyTagColor.backgroundColor"
          class="row no-gutters carousel-card-header"
        >
          <span
            [style.color]="profile.metadata.bodyTagColor.textColor"
            class="carousel-card-header-text"
            >{{ profile.attachmentOrganizationName }}</span
          >
        </div>
        <div class="row no-gutters carousel-card-body">
          <div class="row w-100">
            <img
              [src]="profile.picturePath"
              alt="Avatar"
              class="carousel-card-image"
            />
            <div class="ml-auto mt-3">
              <div class="carousel-card-appointment-date">
                {{ profile.metadata.bodyAppointmentDate | date: "dd/MM/yyyy" }}
              </div>
            </div>
          </div>
          <div class="d-flex flex-column mt-3 p-3">
            <div class="carousel-card-display-name">
              {{ profile.displayName }}
            </div>
            <div class="carousel-card-job">{{ profile.jobLabel }}</div>
            <div class="carousel-card-grade">
              {{ profile.metadata.gradeLabel }}
            </div>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template carouselSlide>
      <div
        (click)="onSearchAllMagistrateInYear(true)"
        class="carousel-card card-see-all"
      >
        <div class="d-flex flex-column card-see-all-body">
          <div class="card-see-all-text">
            Voir tous les magistrats nommés en {{ currentYear }}
          </div>
          <div>
            <img
              alt="skipper_icon"
              class="skipper-icon"
              src="/assets/images/icons/skipper_icon.png"
            />
          </div>
        </div>
      </div>
    </ng-template>
  </owl-carousel-o>
</div>
