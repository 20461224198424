import { Organization } from './organization';
import { capitalize } from '../../../_shared/service/util/string.functions';
import {
  ORGANIZATION,
  ORGANIZATION_LABEL_STR,
  ORGANIZATION_LONG_LABEL,
  ORGANIZATION_SHORT_LABEL,
} from './organization.strings';

export function getOrganizationChildrenCodes(
  organization: Organization
): string[] {
  if (!organization) return [];
  let codes: string[] = [];
  codes.push(organization.code);
  if (organization.children && organization.children.length > 0) {
    organization.children.forEach(
      (child) => (codes = codes.concat(getOrganizationChildrenCodes(child)))
    );
  }
  return codes;
}

export function getFirstAttachmentOrganization(
  organizationChildrenCodes: string[][],
  parentOrganizations: Organization[],
  childrenCode: string
): Organization {
  return parentOrganizations.find(
    (po) =>
      po.code ===
      organizationChildrenCodes.find((codes) =>
        codes.includes(childrenCode)
      )?.[0]
  );
}

export function getFormatLabelOrganization(
  organization: Organization
): Organization {
  let shortLabel = '';
  const partLabels = organization.shortLabel.split(' ');
  if (partLabels.length >= 4) {
    if (partLabels.find((l) => l === ORGANIZATION_LABEL_STR.DE)) {
      shortLabel = ORGANIZATION_LABEL_STR.ORGANIZATION_WITHOUT_DE_ADDITIONAL.replace(
        '{{shortOrg}}',
        partLabels[0].toLocaleUpperCase()
      )
        .replace('{{orgName}}', capitalize(partLabels[2]))
        .replace(
          '{{additional}}',
          partLabels.slice(3).join(' ').toLocaleLowerCase()
        );
    } else {
      shortLabel = ORGANIZATION_LABEL_STR.ORGANIZATION_WITHOUT_DE_ADDITIONAL.replace(
        '{{shortOrg}}',
        partLabels[0].toLocaleUpperCase()
      )
        .replace('{{orgName}}', capitalize(partLabels[1]))
        .replace(
          '{{additional}}',
          partLabels.slice(2).join(' ').toLocaleLowerCase()
        );
    }
  } else if (
    partLabels.find((l) =>
      l.startsWith(ORGANIZATION_LABEL_STR.D_QUOTATION_MARK)
    )
  ) {
    shortLabel = ORGANIZATION_LABEL_STR.ORGANIZATION_WITH_D_QUOTATION_MARK_ADDITIONAL.replace(
      '{{shortOrg}}',
      partLabels[0].toLocaleUpperCase()
    )
      .replace(
        '{{orgName}}',
        capitalize(partLabels[1].substr(2, partLabels[1].length))
      )
      .replace(
        '{{additional}}',
        partLabels.slice(2).join(' ').toLocaleLowerCase()
      );
  } else if (partLabels.length === 1) {
    shortLabel = partLabels[0].toLocaleUpperCase();
  } else {
    shortLabel = ORGANIZATION_LABEL_STR.ORGANIZATION_WITH_DE.replace(
      '{{shortOrg}}',
      partLabels[0].toLocaleUpperCase()
    ).replace('{{orgName}}', capitalize(partLabels[2]));
  }
  shortLabel = shortLabel.trim();
  return {
    ...organization,
    shortLabel: ORGANIZATION_SHORT_LABEL[shortLabel] ?? shortLabel,
    longLabel:
      ORGANIZATION_LONG_LABEL[organization.longLabel] ?? organization.longLabel,
  };
}

export function getBodyTagColor(
  organizationType: string
): { textColor: string; backgroundColor: string } {
  return {
    textColor:
      ORGANIZATION.COLORS[organizationType]?.textColor ??
      ORGANIZATION.COLORS['OTHER'].textColor,
    backgroundColor:
      ORGANIZATION.COLORS[organizationType]?.backgroundColor ??
      ORGANIZATION.COLORS['OTHER'].backgroundColor,
  };
}
