import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProfileSheetPageComponent } from './profile-sheet-page.component';
import { SearchModule } from '../_shared/search/search.module';
import { MatIconModule } from '@angular/material/icon';
import { ProfileSheetPageStoreModule } from './_store/module';
import { ProfileSheetCareerModule } from '../_shared/profile-sheet/career/profile-sheet-career.module';
import { NgxPrintModule } from 'ngx-print';

@NgModule({
  declarations: [ProfileSheetPageComponent],
  imports: [
    CommonModule,
    SearchModule,
    MatIconModule,
    ProfileSheetPageStoreModule,
    ProfileSheetCareerModule,
    NgxPrintModule,
  ],
})
export class ProfileSheetPageModule {}
