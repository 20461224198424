import { Profile } from '../profile/profile';
import { ProfileRow } from './profile-row';
import { PROFILE_ROW } from './profile-row.strings';
import { Organization } from '../organization/organization';
import {
  getBodyTagColor,
  getFirstAttachmentOrganization,
} from '../organization/organization.functions';
import { compareUsingProperties } from '../../../_shared/functions/compare.functions';
import { getJobWithoutHF } from '../job/job.functions';
import { LocalDate } from '../../../_shared/domain/local-date';
import { AVATAR_PATH } from '../profile/profile.strings';
import { getGrade } from '../code-management/code-management.functions';
import { Grade } from '../code-management/code-management';

function compareProfileDisplayName(a: ProfileRow, b: ProfileRow): number {
  return compareUsingProperties(a, b, ['displayName']);
}

function getProfileDisplayName(lastName: string, firstName: string): string {
  return PROFILE_ROW.DISPLAY_NAME.replace(
    '{{lastName}}',
    lastName.toUpperCase()
  ).replace('{{firstName}}', firstName);
}

function getGradeLabelDate(
  gradeCode: string,
  gradeDate: string,
  allGrades: Grade[]
): string {
  return PROFILE_ROW.GRADE_LABEL_DATE.replace(
    '{{gradeLabel}}',
    getGrade(gradeCode, allGrades).shortLabel
  ).replace('{{gradeDate}}', new LocalDate(gradeDate).getDayMonthYear());
}

function getGradeLabel(gradeCode: string, allGrades): string {
  return getGrade(gradeCode, allGrades).shortLabel;
}

function createProfileRow(
  profile: Profile,
  organizationChildrenCodes: string[][],
  parentOrganizations: Organization[],
  allGrades: Grade[]
): ProfileRow {
  const attachmentOrganization = getFirstAttachmentOrganization(
    organizationChildrenCodes,
    parentOrganizations,
    profile.post.organization.code
  );

  const organizationType = attachmentOrganization.shortLabel.split(' ')[0];

  return {
    picturePath: AVATAR_PATH.concat(profile.pictureName),
    displayName: getProfileDisplayName(profile.lastName, profile.firstName),
    jobLabel: getJobWithoutHF(profile.job.longLabel),
    gradeLabel: getGradeLabelDate(
      profile.gradeCode,
      profile.gradeDate,
      allGrades
    ),
    emailLabel: profile.email?.toLocaleLowerCase(),
    phoneLabel: profile.businessPhone || '',
    attachmentOrganizationName: attachmentOrganization.shortLabel,
    metadata: {
      profileId: profile.id,
      bodyAppointmentDate: profile.bodyDate,
      bodyAppointmentModeCode: profile.bodyAttachmentModeCode,
      bodyTagColor: getBodyTagColor(organizationType),
      gradeLabel: getGradeLabel(profile.gradeCode, allGrades),
    },
  };
}

export function getSearchRequestedProfileRows(
  parentOrganizations: Organization[],
  organizationChildrenCodes: string[][],
  profiles: Profile[],
  allGrades: Grade[]
): ProfileRow[] {
  return profiles
    .slice()
    .map((profile: Profile) =>
      createProfileRow(
        profile,
        organizationChildrenCodes,
        parentOrganizations,
        allGrades
      )
    )
    .sort(compareProfileDisplayName);
}
