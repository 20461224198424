import { createAction, props } from '@ngrx/store';
import { Profile } from '../_shared/profile/profile';
import { Job } from '../_shared/job/job';
import { CodeManagement } from '../_shared/code-management/code-management';
import { Organization } from '../_shared/organization/organization';
import { SearchRequest } from '../_shared/search/search-request';
import { AppointmentMode } from '../_shared/appointment-mode/appointment-mode';
import { DetachmentOrganization } from '../_shared/detachment-organization/detachment-organization';

export const restoreNavbarPageState = createAction(
  '[NAVBAR-PAGE] restore navbar page state'
);

export const referentialsFetchRequested = createAction(
  '[NAVBAR-PAGE] Referentials Requested'
);

export const referentialsFetchSuccess = createAction(
  '[NAVBAR-PAGE] Referentials Success',
  props<{
    profiles: Profile[];
    organizations: Organization[];
    jobs: Job[];
    codeManagement: CodeManagement;
    appointmentModes: AppointmentMode[];
    detachmentOrganizations: DetachmentOrganization[];
  }>()
);

export const organizationSelected = createAction(
  '[NAVBAR ITEM] organization selected',
  props<{ organization: Organization }>()
);

// PROFILE SHEET
export const closeProfileSheetDialog = createAction(
  '[CLOSE BUTTON Profile Details Dialog] Close profile details'
);

export const openProfileSheetDialogProfileId = createAction(
  '[Effect] Open profile details dialog profile id',
  props<{ profileId: number }>()
);

// SEARCH-SIDEBAR
export const openSearchSidebar = createAction(
  '[OPEN SEARCH SIDEBAR] Open search sidebar'
);
export const closeSearchSidebar = createAction(
  '[CLOSE SEARCH SIDEBAR] Close search sidebar'
);
export const searchRequested = createAction(
  '[SEARCH-SIDEBAR] Search requested',
  props<{ searchRequested: SearchRequest }>()
);
export const startNavbarPageLoading = createAction(
  '[NAVBAR-PAGE ITEM] start loading'
);
export const stopNavbarPageLoading = createAction(
  '[NAVBAR-Page ITEM] stop loading'
);

// PROFILE SHEET PAGE
export const openSheetPageProfileDialog = createAction(
  '[Effect] Open sheet page profile dialog'
);

export const openProfileSheetDialog = createAction(
  '[NAVBAR-PAGE ACTION] open profile sheet dialog'
);

export const scrollToProfileId = createAction(
  '[NAVBAR-PAGE ACTION] scroll to profile id'
);
