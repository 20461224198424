<div class="ama-top-bar col-12">
  <div class="row">
    <div
      [routerLink]="backUrl$ | async"
      *ngIf="backButtonUrls.includes(url)"
      class="col-6 back-component"
    >
      <button class="ama-button ama-button_icon page-back-button">
        <mat-icon svgIcon="page_back">Retour page précedent</mat-icon>
      </button>
      <span class="page-back-text">Retour page précedente</span>
    </div>
    <div [ngClass]="backButtonUrls.includes(url) ? 'col-6' : 'col-12'">
      <ama-open-search-button></ama-open-search-button>
    </div>
  </div>
</div>
