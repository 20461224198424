import { Component, Input } from '@angular/core';
import { ProfileRow } from '../../_shared/profile-row/profile-row';

@Component({
  selector: 'ama-search-row',
  templateUrl: './search-row.component.html',
  styleUrls: ['./search-row.component.scss'],
})
export class SearchRow {
  @Input() row: ProfileRow;
}
