import { Resource } from '../../../_shared/service/resource/resource';
import { Profile } from './profile';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { getRandomDefaultPicture } from './profile.functions';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  private profileResource: Resource<Profile>;

  constructor(private httpClient: HttpClient) {
    this.profileResource = new Resource<Profile>(httpClient, 'profiles');
  }

  getAll$(): Observable<Profile[]> {
    return this.profileResource
      .getAll()
      .pipe(
        map((profiles) =>
          profiles.map((profile) => ({
            ...profile,
            pictureName: getRandomDefaultPicture(profile.pictureName),
          }))
        )
      );
  }
}
