import { Component, OnInit } from '@angular/core';
import { DestroyListener } from '../../_shared/service/widget/destroy-listener/destroy-listener';
import { Observable, Subject } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { takeUntil, tap } from 'rxjs/operators';
import { ProfileSheet } from '../_shared/profile-sheet/profile-sheet';
import {
  onClosedProfileSheetDialog,
  onOpenSheetPageProfileDialog,
} from './_store/profile-sheet-dialog.actions';
import {
  selectIsVisibleProfileSheet,
  selectProfileSheet,
} from '../_store/navbar-page.selectors';
import { ScrollToService } from '../../_shared/service/widget/scroll/scroll-to.service';

@Component({
  selector: 'ama-profile-sheet-dialog',
  templateUrl: './profile-sheet-dialog.component.html',
  styleUrls: ['./profile-sheet-dialog.component.scss'],
})
export class ProfileSheetDialogComponent extends DestroyListener
  implements OnInit {
  profileSheet$: Observable<ProfileSheet>;
  isVisible$: Observable<boolean>;
  scrollTop$ = new Subject<void>();

  constructor(
    private store: Store<any>,
    private scrollService: ScrollToService
  ) {
    super();
  }

  ngOnInit(): void {
    this.profileSheet$ = this.store.pipe(
      select(selectProfileSheet),
      takeUntil(this.destroyed$),
      tap(() => this.scrollTop$.next())
    );

    this.isVisible$ = this.store.pipe(
      select(selectIsVisibleProfileSheet),
      takeUntil(this.destroyed$)
    );
    this.scrollTop$.subscribe(() =>
      this.scrollService.scrollToTop('#sheet-dialog-container')
    );
  }

  openSheetPage(): void {
    this.store.dispatch(onOpenSheetPageProfileDialog());
  }

  onClosedProfileDialog() {
    this.store.dispatch(onClosedProfileSheetDialog());
  }
}
