import { Action, createReducer, on } from '@ngrx/store';
import {
  initialNavbarPageStates,
  NavbarPageStates,
} from './navbar-page.states';
import {
  closeProfileSheetDialog,
  closeSearchSidebar,
  openProfileSheetDialog,
  openProfileSheetDialogProfileId,
  openSearchSidebar,
  openSheetPageProfileDialog,
  organizationSelected,
  referentialsFetchSuccess,
  restoreNavbarPageState,
  searchRequested,
  startNavbarPageLoading,
  stopNavbarPageLoading,
} from './navbar-page.action';
import { redirectAction } from '../../_store/ama.actions';

const reducer = createReducer(
  initialNavbarPageStates,
  on(restoreNavbarPageState, () => initialNavbarPageStates),
  on(referentialsFetchSuccess, (state, action) => ({
    ...state,
    referential: {
      profiles: action.profiles,
      organizations: action.organizations,
      jobs: action.jobs,
      codeManagement: action.codeManagement,
      appointmentModes: action.appointmentModes,
      detachmentOrganizations: action.detachmentOrganizations,
    },
  })),
  on(closeProfileSheetDialog, (state) => ({
    ...state,
    selectedProfileId: null,
    isVisibleProfileSheetDialog: false,
  })),
  on(openProfileSheetDialogProfileId, (state, action) => ({
    ...state,
    selectedProfileId: action.profileId,
    isVisibleProfileSheetDialog: true,
  })),
  on(organizationSelected, (state, action) => ({
    ...state,
    selectedOrganization: action.organization,
  })),
  on(openSearchSidebar, (state) => ({
    ...state,
    openSearchSidebar: true,
  })),
  on(closeSearchSidebar, (state) => ({
    ...state,
    openSearchSidebar: false,
  })),
  on(searchRequested, (state, action) => ({
    ...state,
    searchRequest: action.searchRequested,
  })),
  on(redirectAction, (state, action) => ({
    ...state,
    selectedOrganization:
      action.url === 'sheet' ? state.selectedOrganization : null,
  })),
  on(startNavbarPageLoading, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(stopNavbarPageLoading, (state) => ({
    ...state,
    isLoading: false,
  })),
  on(openSheetPageProfileDialog, (state) => ({
    ...state,
    isVisibleProfileSheetDialog: false,
  })),
  on(openProfileSheetDialog, (state) => ({
    ...state,
    isVisibleProfileSheetDialog: true,
  }))
);

export function NavbarPageReducer(
  state: NavbarPageStates | undefined,
  action: Action
) {
  return reducer(state, action);
}
