<div class="container-fluid no-gutters app h-100">
  <div class="row no-gutters h-100">
    <div class="navbar-page-navbar col-2 ama-navbar h-100 w-100">
      <ama-navbar></ama-navbar>
    </div>
    <div
      [ngClass]="{ 'scroll-y': scrollPages.includes(url) }"
      class="col-10 h-100"
    >
      <div class="col-12 px-0">
        <div
          *ngIf="!noTopBarPages.includes(url)"
          class="navbar-page-top-bar col-12 mt-3"
        >
          <ama-top-bar></ama-top-bar>
        </div>
        <div
          [ngClass]="{ 'mt-3': !noTopBarPages.includes(url) }"
          class="col-12 px-5"
        >
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
  </div>
</div>
