import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BookPageComponent } from './book-page.component';
import { SearchResultsPageModule } from '../search-results-page/search-results-page.module';
import { JobAnchorComponent } from './job-anchor/job-anchor.component';
import { ProfileJobsComponent } from './profile-jobs/profile-jobs.component';
import { CardModule } from 'primeng/card';
import { AmaWidgetModule } from '../../_shared/service/widget/ama-widget.module';
import { JobAnchorStoreModule } from './job-anchor/_store/module';
import { BookPageStoreModule } from './_store/module';
import { ProfileJobsStoreModule } from './profile-jobs/_store/module';
import { SearchModule } from '../_shared/search/search.module';

@NgModule({
  declarations: [BookPageComponent, JobAnchorComponent, ProfileJobsComponent],
  imports: [
    CommonModule,
    SearchResultsPageModule,
    CardModule,
    AmaWidgetModule,
    JobAnchorStoreModule,
    BookPageStoreModule,
    ProfileJobsStoreModule,
    SearchModule,
  ],
})
export class BookPageModule {}
