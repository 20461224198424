export function distinctValues(array: any[]): any[] {
  return array.filter((v, i, a) => a.indexOf(v) === i);
}

export function overlaps(array1: any[], array2: any[]): boolean {
  if (!array1 || !array2) {
    return false;
  }
  return array1.some((e) => array2.includes(e));
}

export function sameIgnoringOrder(array1: any[], array2: any[]): boolean {
  if (!array1 || !array2) {
    return !array1 && !array2;
  }
  return containsAll(array1, array2) && containsAll(array2, array1);
}

export function containsAll(container: any[], content: any[]): boolean {
  if (!container || !content) {
    return false;
  }
  return content.every((e) => container.includes(e));
}

export function sample(array: any[], size: number): any[] {
  if (size < array.length) {
    let sample = [];
    let chosenIndexes = [];

    while (sample.length < size) {
      let randomIndex = Math.floor(Math.random() * array.length);
      if (!chosenIndexes.includes(randomIndex)) {
        chosenIndexes.push(randomIndex);
        sample.push(array[randomIndex]);
      }
    }
    return sample;
  } else {
    return array;
  }
}
