import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { BookPageReducer } from './book-page.reducer';
import { EffectsModule } from '@ngrx/effects';
import { BookPageEffects } from './book-page.effects';

@NgModule({
  imports: [
    EffectsModule.forFeature([BookPageEffects]),
    StoreModule.forFeature('BOOK-PAGE', BookPageReducer)
  ],
})
export class BookPageStoreModule {}
