import { Injectable } from '@angular/core';
import { Actions, createEffect, Effect, ofType } from '@ngrx/effects';
import { map, tap } from 'rxjs/operators';
import { openProfileSheetDialogProfileId } from '../../../_store/navbar-page.action';
import { onProfileJobSelectCardProfileId } from './profile-jobs.actions';
import { matomoTrackEvent } from '../../../../_store/ama.actions';
import { Store } from '@ngrx/store';

@Injectable()
export class ProfileJobsEffects {
  @Effect() selectedCardProfileId$ = this.actions$.pipe(
    ofType(onProfileJobSelectCardProfileId),
    map((action) =>
      openProfileSheetDialogProfileId({ profileId: action.profileId })
    )
  );

  selectedCardProfileIdTrackEvent$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(onProfileJobSelectCardProfileId),
        tap((action) =>
          this.store.dispatch(
            matomoTrackEvent({
              category: 'Card',
              action: 'Sélectionner un magistrat',
              name: action.profileId.toString(),
            })
          )
        )
      ),
    { dispatch: false }
  );

  constructor(private actions$: Actions, private store: Store<any>) {}
}
