import { Injectable } from '@angular/core';
import { Login } from './login';
import { AuthServerProvider } from '../auth/auth-jwt.service';
import { flatMap } from 'rxjs/operators';
import { AccountService } from '../auth/account.service';
import { Observable } from 'rxjs';
import { User } from '../../domain/user';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  constructor(
    private accountService: AccountService,
    private authServerProvider: AuthServerProvider
  ) {}

  login(credentials: Login): Observable<User> {
    return this.authServerProvider
      .login(credentials)
      .pipe(flatMap(() => this.accountService.identity(true)));
  }

  logout(): void {
    this.authServerProvider
      .logout()
      .subscribe(null, null, () => this.accountService.authenticate(null));
  }
}
