import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  selectAllAppointmentModes,
  selectAllOrganizationChildrenCodes,
  selectAllOrganizations,
  selectAllParentOrganizations,
  selectAllProfiles,
  selectGrades,
  selectJobs,
  selectSearchRequest,
} from '../../_store/navbar-page.selectors';
import { getSearchRequestedProfileRows } from '../../_shared/profile-row/profile-rows.functions';
import { SEARCH_RESULTS } from '../search-results-page.strings';
import { LocalDate } from '../../../_shared/domain/local-date';
import { distinctValues } from '../../../_shared/functions/arrays.functions';
import { SearchResultsPageStates } from './search-results-page.states';
import { YEAR_MAX } from '../../../app.constants';
import { getBodyAppointmentModeByProfile } from '../body-appointment-mode/body-appointment-mode.functions';
import { getSearchProfile } from '../search-results.functions';
import { getCurrentYear } from '../../home-page/home-page.functions';
import { ORGANIZATION_LONG_LABEL } from '../../_shared/organization/organization.strings';

const selectFeature = createFeatureSelector<SearchResultsPageStates>(
  'SEARCH-RESULTS'
);
const selectFilterGradesProfiles = createSelector(
  selectAllProfiles,
  (profiles) => profiles.filter((profile) => !!profile.gradeDate)
);
const selectProfileDistinctBodyAppointmentDateYears = createSelector(
  selectFilterGradesProfiles,
  (profiles) =>
    distinctValues(
      profiles.map((profile) => new LocalDate(profile.bodyDate).getYear())
    )
);

const selectSearchProfiles = createSelector(
  selectFilterGradesProfiles,
  selectAllOrganizationChildrenCodes,
  selectSearchRequest,
  getSearchProfile
);

export const selectSearchBodyAppointmentModes = createSelector(
  selectSearchProfiles,
  selectAllAppointmentModes,
  getBodyAppointmentModeByProfile
);

const selectStateRangeBodyDateYears = createSelector(selectFeature, (state) => [
  state.minDate,
  state.maxDate,
]);

const selectSelectedBodyAppointmentModesCodes = createSelector(
  selectFeature,
  (state) =>
    state.selectedBodyAppointmentModes
      ? state.selectedBodyAppointmentModes.map((m) => m.code)
      : []
);

export const selectScrollIndex = createSelector(
  selectFeature,
  (state) => state.scrollToIndex
);

export const selectSearchFilterProfiles = createSelector(
  selectSearchProfiles,
  selectStateRangeBodyDateYears,
  selectSelectedBodyAppointmentModesCodes,
  (profiles, rangeDates, appointmentModesCodes) =>
    profiles
      .filter((p) => new LocalDate(p.bodyDate).getYear() >= rangeDates[0])
      .filter((p) => new LocalDate(p.bodyDate).getYear() <= rangeDates[1])
      .filter(
        (p) =>
          appointmentModesCodes?.length === 0 ||
          appointmentModesCodes.includes(p.bodyAttachmentModeCode)
      )
);

export const selectSearchRequestedProfileRows = createSelector(
  selectAllParentOrganizations,
  selectAllOrganizationChildrenCodes,
  selectSearchFilterProfiles,
  selectGrades,
  getSearchRequestedProfileRows
);

export const selectMinBodyDateYear = createSelector(
  selectProfileDistinctBodyAppointmentDateYears,
  (dates) => Math.min(...dates)
);

export const selectMaxBodyDateYear = createSelector(
  selectProfileDistinctBodyAppointmentDateYears,
  (dates) => Math.max(...dates)
);

const selectProfileRowDistinctBodyAppointmentDateYears = createSelector(
  selectSearchRequestedProfileRows,
  (rows) =>
    distinctValues(
      rows.map((row) =>
        new LocalDate(row.metadata.bodyAppointmentDate).getYear()
      )
    )
);

export const selectCurrentMinBodyDateYear = createSelector(
  selectProfileRowDistinctBodyAppointmentDateYears,
  selectProfileDistinctBodyAppointmentDateYears,
  (rowDates, profileDates) =>
    rowDates.length > 0 ? Math.min(...rowDates) : Math.min(...profileDates)
);

export const selectCurrentMaxBodyDateYear = createSelector(
  selectProfileRowDistinctBodyAppointmentDateYears,
  (dates) => (dates.length > 0 ? Math.max(...dates) : YEAR_MAX)
);

export const selectCurrentRangeBodyDateYears = createSelector(
  selectCurrentMinBodyDateYear,
  selectCurrentMaxBodyDateYear,
  (min, max) => [min, max]
);

const selectSearchTotalCount = createSelector(
  selectSearchRequestedProfileRows,
  (rows) => (rows ? rows.length : 0)
);

export const selectSearchTitleCount = createSelector(
  selectSearchTotalCount,
  (count) =>
    SEARCH_RESULTS.RESULT_COUNT.replace(
      '{{count}}',
      count.toLocaleString()
    ).replace('{{s}}', count > 1 ? 's' : '')
);

export const selectSearchTitle = createSelector(
  selectSearchRequest,
  selectGrades,
  selectJobs,
  selectAllOrganizations,
  (searchRequested, grades, jobs, organizations) => {
    let items = [];
    if (
      !searchRequested ||
      ((searchRequested.name == null || searchRequested.name.trim() === '') &&
        searchRequested.jobCode == null &&
        searchRequested.organizationCode == null &&
        searchRequested.gradeCode == null &&
        searchRequested.appointmentYear == null)
    ) {
      return {
        title: SEARCH_RESULTS.TITLE_ALL_MAGISTRATE,
        allMagistrates: true,
      };
    }

    if (searchRequested.appointmentYear === getCurrentYear()) {
      return {
        title: SEARCH_RESULTS.TITLE_APPOINTED_THIS_YEAR.replace(
          '{{year}}',
          getCurrentYear().toString()
        ),
      };
    }

    if (searchRequested.name !== null && searchRequested.name.trim() !== '') {
      items.push(
        SEARCH_RESULTS.TITLE_ITEMS.replace(
          '{{items}}',
          searchRequested.name
            .split(SEARCH_RESULTS.SEPARATOR.SEMICOLON)
            .join(' ' + SEARCH_RESULTS.SEPARATOR.SEMICOLON + ' ')
        )
      );
    }
    if (searchRequested.organizationCode !== null) {
      const orgLongLabel = organizations.find(
        (o) => o.code === searchRequested.organizationCode
      ).longLabel;
      items.push(
        SEARCH_RESULTS.TITLE_ITEMS.replace(
          '{{items}}',
          ORGANIZATION_LONG_LABEL[orgLongLabel] ?? orgLongLabel
        )
      );
    }
    if (searchRequested.gradeCode !== null) {
      items.push(
        SEARCH_RESULTS.TITLE_ITEMS.replace(
          '{{items}}',
          grades.find((g) => g.code === searchRequested.gradeCode).shortLabel
        )
      );
    }
    if (searchRequested.jobCode !== null) {
      items.push(
        SEARCH_RESULTS.TITLE_ITEMS.replace(
          '{{items}}',
          jobs.find((j) => j.code === searchRequested.jobCode).longLabel
        )
      );
    }
    return {
      title: SEARCH_RESULTS.SEARCH_TITLE,
      items: items.join(SEARCH_RESULTS.COMMA),
    };
  }
);
