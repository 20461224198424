import { YEAR_MAX, YEAR_MIN } from '../../../app.constants';
import { BodyAppointmentMode } from '../body-appointment-mode/body-appointment-mode';

export interface SearchResultsPageStates {
  minDate: number;
  maxDate: number;
  selectedBodyAppointmentModes: BodyAppointmentMode[];
  scrollToIndex: number;
}

// Initial
export const initialSearchResultsPageStates: SearchResultsPageStates = {
  minDate: YEAR_MIN,
  maxDate: YEAR_MAX,
  selectedBodyAppointmentModes: [],
  scrollToIndex: 0,
};
