import { Component, OnInit } from '@angular/core';
import { onOpenedSearchSidebar } from '../_store/search.actions';
import { Store } from '@ngrx/store';

@Component({
  selector: 'ama-open-search-button',
  templateUrl: './open-search-button.component.html',
  styleUrls: ['./open-search-button.component.scss'],
})
export class OpenSearchButtonComponent implements OnInit {
  constructor(private store: Store<any>) {}

  ngOnInit(): void {}

  onOpenedSearch() {
    this.store.dispatch(onOpenedSearchSidebar());
  }
}
