import { EnvInfo } from '../_shared/domain/env-info/env-info';

export interface AmaStates {
  hasLoggedOut: boolean;
  environment: EnvInfo;
  backUrl: string;
}

// Initial
export const initialAmaStates: AmaStates = {
  hasLoggedOut: false,
  environment: null,
  backUrl: null,
};
