import { Routes } from '@angular/router';
import { SearchResultsPageComponent } from './search-results-page.component';
import { UserRouteAccessService } from '../../_shared/service/security/user-route-access.service';
import { Authority } from '../../_shared/service/security/authorities.enum';

export const searchResultsPageRoutes: Routes = [
  {
    path: 'search',
    data: {
      authorities: [Authority.ROLE_USER],
    },
    canActivate: [UserRouteAccessService],
    children: [
      {
        path: '',
        component: SearchResultsPageComponent,
      },
    ],
  },
];
