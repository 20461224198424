import startOfDay from 'date-fns/startOfDay';
import format from 'date-fns/format';
import getYear from 'date-fns/getYear';
import addYears from 'date-fns/addYears';
import getTime from 'date-fns/getTime';
import { isFuture, isPast } from 'date-fns';
import fr from 'date-fns/locale/fr';

export class LocalDate {
  dateFns: Date;

  constructor(arg?: Date | string) {
    if (typeof arg == 'undefined') {
      arg = new Date();
    }
    if (typeof arg == 'string') {
      arg = startOfDay(new Date(arg));
    }
    this.dateFns = startOfDay(arg);
  }

  public toString(): string {
    return format(this.dateFns, 'YYYY-MM-DD');
  }

  public getDayMonthYear(): string {
    return format(this.dateFns, 'dd/MM/yyyy');
  }

  public getYear(): number {
    return getYear(this.dateFns);
  }

  public getYearStr(): string {
    return getYear(this.dateFns).toString();
  }

  public addYears(count: number): Date {
    return addYears(this.dateFns, count);
  }

  public getTime(): number {
    return getTime(this.dateFns);
  }

  public isFuture(dateStr: string): boolean {
    return isFuture(new Date(dateStr));
  }

  public getMonthYearStr(): string {
    return format(this.dateFns, 'MMMM-yyyy', { locale: fr });
  }

  public isTodayBetweenDates(startDate: string, endDate: string): boolean {
    return isPast(new Date(startDate)) && isFuture(new Date(endDate));
  }
}
