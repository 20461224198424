import { environment } from '../../../../environments/environment';
import { Injectable } from '@angular/core';

// @ts-ignore
@Injectable({
  providedIn: 'root',
})
export class LogService {
  // noinspection JSMethodCanBeStatic
  debug(message: string) {
    if (!environment.production && environment.debug) {
      console.log('DEBUG:' + message);
    }
  }

  // noinspection JSMethodCanBeStatic
  error(message: any) {
    console.error(message);
  }
}
