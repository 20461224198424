import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  backToHomeAction,
  effectError,
  initialAMA,
  logout,
  matomoTrackEvent,
  redirectAction,
  setEnvironment,
  setUrlBack,
} from './ama.actions';
import { catchError, first, map, switchMap, tap } from 'rxjs/operators';
import { LogService } from '../_shared/service/front/log.service';
import { Injectable } from '@angular/core';
import { LoginService } from '../_shared/core/login/login.service';
import { Router } from '@angular/router';
import { EnvInfoService } from '../_shared/domain/env-info/env-info.service';
import { of } from 'rxjs';
import { MatomoTracker } from 'ngx-matomo';
import { select, Store } from '@ngrx/store';
import { selectCurrentUrl } from './ama.selectors';

@Injectable({
  providedIn: 'root',
})
export class AmaEffects {
  onError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(effectError),
        tap((error) => this.logService.error(error))
      ),
    { dispatch: false }
  );

  redirectAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(redirectAction),
      switchMap((action) =>
        this.store.pipe(
          first(),
          select(selectCurrentUrl),
          map((url) => setUrlBack({ url })),
          tap(() => this.router.navigate([action.url])),
          catchError((error) => of(effectError({ error, source: action })))
        )
      )
    )
  );

  logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(logout),
      tap(() => this.loginService.logout()),
      map(() => redirectAction({ url: 'login' }))
    )
  );

  $env = createEffect(() =>
    this.actions$.pipe(
      ofType(initialAMA),
      switchMap((action) =>
        this.envInfoService.getProfileInfo().pipe(
          map((envInfo) => setEnvironment({ environment: envInfo })),
          catchError((error) => of(effectError({ error, source: action })))
        )
      )
    )
  );

  trackEvent$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(matomoTrackEvent),
        tap((action) =>
          new MatomoTracker().trackEvent(
            action.category,
            action.action,
            action.name,
            action.value
          )
        )
      ),
    { dispatch: false }
  );

  backToHomeActionTrackEvent = createEffect(
    () =>
      this.actions$.pipe(
        ofType(backToHomeAction),
        tap(() => this.store.dispatch(redirectAction({ url: 'home' }))),
        tap(() =>
          this.store.dispatch(
            matomoTrackEvent({
              category: 'Logo',
              action: "Retour à l'accueil",
            })
          )
        )
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private logService: LogService,
    private loginService: LoginService,
    private router: Router,
    private envInfoService: EnvInfoService,
    private store: Store<any>
  ) {}
}
