import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PageRibbonComponent } from './ribbon/page-ribbon.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NavbarPageModule } from './navbar-page/navbar-page.module';
import { AmaWidgetModule } from './_shared/service/widget/ama-widget.module';
import { MatIconModule } from '@angular/material/icon';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AmaStoreModule } from './_store/module';
import { LoginModule } from './login/login.module';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { AuthInterceptor } from './_shared/service/interceptor/auth.interceptor';
import { AuthExpiredInterceptor } from './_shared/service/interceptor/auth-expired.interceptor';
import { CookieComponent } from './cookie/cookie.component';
import { CookieService } from 'ngx-cookie-service';
import { MatomoModule } from 'ngx-matomo';
import { MatomoInjector } from './_shared/service/matomo/matomo-injector.service';
import { SearchModule } from './navbar-page/_shared/search/search.module';
import { ProfileSheetDialogModule } from './navbar-page/profile-sheet-dialog/profile-sheet-dialog.module';

@NgModule({
  declarations: [AppComponent, PageRibbonComponent, CookieComponent],
  imports: [
    LoginModule,
    AmaStoreModule,
    BrowserModule,
    NgxWebstorageModule.forRoot(),
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    NavbarPageModule,
    AmaWidgetModule,
    MatIconModule,
    HttpClientModule,
    MatomoModule,
    SearchModule,
    ProfileSheetDialogModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthExpiredInterceptor,
      multi: true,
    },
    CookieService,
    MatomoInjector,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
