export class ProfileRow {
  readonly picturePath: string;
  readonly displayName: string;
  readonly jobLabel: string;
  readonly gradeLabel: string;
  readonly emailLabel: string;
  readonly phoneLabel: string;
  readonly attachmentOrganizationName: string;
  readonly metadata: ProfileRowMetadata;
}

class ProfileRowMetadata {
  profileId: number;
  bodyAppointmentDate: string;
  bodyAppointmentModeCode: string;
  bodyTagColor: { textColor: string; backgroundColor: string };
  gradeLabel: string;
}
