import {
  overlaps,
  sameIgnoringOrder,
} from '../../_shared/functions/arrays.functions';
import { Profile } from '../_shared/profile/profile';
import { SearchRequest } from '../_shared/search/search-request';
import { getBodyAppointmentYear } from '../home-page/home-page.functions';
import { SEARCH_RESULTS } from './search-results-page.strings';
import { removeCaseAndAccent } from '../../_shared/service/util/string.functions';

function formatNameArray(separator: string, name: string): string[] {
  return name
    .trim()
    .toLowerCase()
    .split(separator)
    .filter((part) => !!part)
    .map((part) => part.trim())
    .map((part) => removeCaseAndAccent(part));
}

export function getProfileFullNameStr(profile: Profile): string {
  return (
    removeCaseAndAccent(profile.firstName) +
    SEARCH_RESULTS.SEPARATOR.SPACE +
    removeCaseAndAccent(profile.lastName)
  );
}

function isSameIgnoringOrderMatchProfile(
  nameArray: string[],
  profile: Profile
): boolean {
  return sameIgnoringOrder(
    nameArray,
    formatNameArray(
      SEARCH_RESULTS.SEPARATOR.SPACE,
      getProfileFullNameStr(profile)
    )
  );
}

function isOverlapsMatchProfile(
  nameArray: string[],
  profile: Profile
): boolean {
  return (
    overlaps(
      nameArray,
      formatNameArray(
        SEARCH_RESULTS.SEPARATOR.SPACE,
        getProfileFullNameStr(profile)
      )
    ) || getProfileFullNameStr(profile).includes(nameArray.toString())
  );
}

function isFullNamesMatch(
  profile: Profile,
  searchRequest: SearchRequest
): boolean {
  if (!searchRequest.name || searchRequest.name.trim().length === 0)
    return true;
  return !!formatNameArray(
    SEARCH_RESULTS.SEPARATOR.SEMICOLON,
    searchRequest.name
  )
    .map((part) => formatNameArray(SEARCH_RESULTS.SEPARATOR.SPACE, part))
    .find((part) => isSameIgnoringOrderMatchProfile(part, profile));
}

function isOverlapsNamesMatch(
  profile: Profile,
  searchRequest: SearchRequest,
  matchToFilterFulName: string[]
): boolean {
  if (!searchRequest.name || searchRequest.name.trim().length === 0)
    return true;

  return !!formatNameArray(
    SEARCH_RESULTS.SEPARATOR.SEMICOLON,
    searchRequest.name
  )
    .filter((part) => !matchToFilterFulName.includes(part))
    .filter((part) => !!part)
    .map((part) => formatNameArray(SEARCH_RESULTS.SEPARATOR.SPACE, part))
    .find((part) => isOverlapsMatchProfile(part, profile));
}

function getCandidateNameProfiles(
  profiles: Profile[],
  searchRequest: SearchRequest
): Profile[] {
  if (!searchRequest.name || searchRequest.name.length === 0) return profiles;
  let fullNameMatchProfiles = profiles.filter((profile) =>
    isFullNamesMatch(profile, searchRequest)
  );
  let fullNameMatchToFilterProfiles = [];
  if (fullNameMatchProfiles.length > 0) {
    fullNameMatchProfiles
      .map((profile) => getProfileFullNameStr(profile).split(' '))
      .forEach((fullNames) =>
        formatNameArray(
          SEARCH_RESULTS.SEPARATOR.SEMICOLON,
          searchRequest.name
        ).forEach((searchName) => {
          if (sameIgnoringOrder(fullNames, searchName.split(' '))) {
            fullNameMatchToFilterProfiles.push(searchName);
          }
        })
      );
  }
  let overlapsNamesMatchProfiles = profiles
    .filter((profile) => !fullNameMatchProfiles.includes(profile))
    .filter((profile) =>
      isOverlapsNamesMatch(
        profile,
        searchRequest,
        fullNameMatchToFilterProfiles
      )
    );
  return [...fullNameMatchProfiles, ...overlapsNamesMatchProfiles];
}

function isOrganizationMatch(
  profile: Profile,
  organizationChildrenCodes: string[][],
  searchRequest: SearchRequest
): boolean {
  if (!searchRequest.organizationCode) return true;
  return organizationChildrenCodes
    .find((codes) => codes.find((c) => c === searchRequest.organizationCode))
    .includes(profile.post.organization.code);
}

function isGradeMatch(profile: Profile, searchRequest: SearchRequest): boolean {
  if (!searchRequest.gradeCode) return true;
  return profile.gradeCode === searchRequest.gradeCode;
}

function isJobMatch(profile: Profile, searchRequest: SearchRequest): boolean {
  if (!searchRequest.jobCode) return true;
  return profile.job.code === searchRequest.jobCode;
}

function isAppointmentYearMatch(
  profile: Profile,
  searchRequest: SearchRequest
): boolean {
  if (!searchRequest.appointmentYear) return true;
  return (
    getBodyAppointmentYear(profile.bodyDate) === searchRequest.appointmentYear
  );
}

function isMatch(
  profile: Profile,
  organizationChildrenCodes: string[][],
  searchRequest: SearchRequest
): boolean {
  return (
    isOrganizationMatch(profile, organizationChildrenCodes, searchRequest) &&
    isGradeMatch(profile, searchRequest) &&
    isJobMatch(profile, searchRequest) &&
    isAppointmentYearMatch(profile, searchRequest)
  );
}

function isNotSearch(searchRequest: SearchRequest): boolean {
  return (
    searchRequest == null ||
    (!searchRequest.name &&
      !searchRequest.organizationCode &&
      !searchRequest.jobCode &&
      !searchRequest.gradeCode &&
      !searchRequest.appointmentYear)
  );
}

export function getSearchProfile(
  profiles: Profile[],
  organizationChildrenCodes: string[][],
  searchRequest: SearchRequest
): Profile[] {
  if (isNotSearch(searchRequest)) return profiles;
  return getCandidateNameProfiles(profiles, searchRequest).filter((profile) =>
    isMatch(profile, organizationChildrenCodes, searchRequest)
  );
}
