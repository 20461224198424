export const MATOMO_IDS = {
  // mock: '0',
  // dev: '0',
  integration: '18',
  recette: '17',
  preprod: '19',
  prod: '20',
};
export const MATOMO_ENV = {
  // mock: '.mock.',
  // dev: '.dev.',
  integration: '.int1.',
  recette: '.recette.',
  preprod: '.preprod.',
  prod: '.',
};
