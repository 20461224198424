import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { SessionStorageService } from 'ngx-webstorage';
import { Observable, of, ReplaySubject } from 'rxjs';
import { catchError, shareReplay, tap } from 'rxjs/operators';
import { StateStorageService } from '../../service/security/state-storage.service';
import { Resource } from '../../service/resource/resource';
import { User } from '../../domain/user';
import { Authority } from '../../service/security/authorities.enum';

@Injectable({ providedIn: 'root' })
export class AccountService {
  private userIdentity: User;
  private authenticationState = new ReplaySubject<User | null>(1);
  private accountCache$?: Observable<User>;
  private accountResource: Resource<User>;

  constructor(
    private sessionStorage: SessionStorageService,
    private http: HttpClient,
    private stateStorageService: StateStorageService,
    private router: Router,
    private httpClient: HttpClient
  ) {
    this.accountResource = new Resource<User>(httpClient, 'account');
  }

  authenticate(identity: User): void {
    this.userIdentity = identity;
    this.authenticationState.next(this.userIdentity);
  }

  hasAnyAuthority(authorities: string[] | string): boolean {
    if (!this.userIdentity || !this.userIdentity.authorities) {
      return false;
    }
    if (!Array.isArray(authorities)) {
      authorities = [authorities];
    }
    return this.userIdentity.authorities.some((authority: string) =>
      authorities.includes(authority)
    );
  }

  hasAuthorityDirect(authority: Authority): boolean {
    if (!this.userIdentity || !this.userIdentity.authorities) {
      return false;
    }
    return this.userIdentity.authorities.includes(authority);
  }

  identity(force?: boolean): Observable<User> {
    if (!this.accountCache$ || force || !this.isAuthenticated()) {
      this.accountCache$ = this.fetch().pipe(
        catchError(() => {
          return of(null);
        }),
        tap((user: User) => {
          this.authenticate(user);

          if (user) {
            if (user.authorities.includes(Authority.ROLE_USER)) {
              this.navigateToStoredUrl();
            }
          }
        }),
        shareReplay()
      );
    }
    return this.accountCache$;
  }

  isAuthenticated(): boolean {
    return this.userIdentity !== null;
  }

  getAuthenticationState(): Observable<User> {
    return this.authenticationState.asObservable();
  }

  private fetch(): Observable<User> {
    return this.accountResource.findOne(null);
  }

  private navigateToStoredUrl(): void {
    // previousState can be set in the authExpiredInterceptor and in the userRouteAccessService
    // if login is successful, go to stored previousState and clear previousState
    const previousUrl = this.stateStorageService.getUrl();
    if (previousUrl) {
      this.stateStorageService.clearUrl();
      this.router.navigateByUrl(previousUrl);
    } else {
      this.router.navigate(['/']);
    }
  }
}
