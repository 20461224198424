import { Injectable } from '@angular/core';
import { Resource } from '../../../_shared/service/resource/resource';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Organization } from './organization';
import { getFormatLabelOrganization } from './organization.functions';

@Injectable({
  providedIn: 'root',
})
export class OrganizationService {
  private organizationResource: Resource<Organization>;

  constructor(private httpClient: HttpClient) {
    this.organizationResource = new Resource<Organization>(
      httpClient,
      'organizations'
    );
  }

  getAll$(): Observable<Organization[]> {
    return this.organizationResource.getAll().pipe(
      map((organizations) =>
        organizations.map((organization) =>
          getFormatLabelOrganization(organization)
        )
      ),
      map((organization) =>
        organization.sort((a, b) => a.shortLabel.localeCompare(b.shortLabel))
      )
    );
  }
}
