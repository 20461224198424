import { createSelector } from '@ngrx/store';
import { selectAllParentOrganizations } from '../../_store/navbar-page.selectors';
import { getNavbarPanels } from '../navbar.functions';

const selectNavbarPanels = createSelector(
  selectAllParentOrganizations,
  getNavbarPanels
);

export const selectNavbarPanelGroups = createSelector(
  selectNavbarPanels,
  (panels) => panels.filter((p) => p.organizations.length > 1)
);

export const selectNavbarPanelSingles = createSelector(
  selectNavbarPanels,
  (panels) => panels.filter((p) => p.organizations.length === 1)
);
