import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AmaStates } from './ama.states';
import * as fromRouter from '@ngrx/router-store';

const selectFeature = createFeatureSelector<AmaStates>('AMA');

export interface State {
  router: fromRouter.RouterReducerState<any>;
}

export const selectHasLoggedOut = createSelector(
  selectFeature,
  (state) => state.hasLoggedOut
);

export const selectEnvironment = createSelector(selectFeature, (state) =>
  state.environment ? state.environment.activeProfiles[0] : ''
);

export const selectIsProductionEnv = createSelector(selectFeature, (state) =>
  state.environment ? state.environment.inProduction : true
);

export const selectBackUrl = createSelector(
  selectFeature,
  (state) => state.backUrl
);

const selectRouter = createFeatureSelector<
  State,
  fromRouter.RouterReducerState<any>
>('router');
export const {
  selectCurrentRoute, // select the current route
  selectFragment, // select the current route fragment
  selectQueryParams, // select the current route query params
  selectQueryParam, // factory function to select a query param
  selectRouteParams, // select the current route params
  selectRouteParam, // factory function to select a route param
  selectRouteData, // select the current route data
  selectUrl, // select the current url
} = fromRouter.getSelectors(selectRouter);

export const selectCurrentUrl = createSelector(selectUrl, (url) => url);
