import { Actions, Effect, ofType } from '@ngrx/effects';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {
  closeProfileSheetDialog,
  openSheetPageProfileDialog,
} from '../../_store/navbar-page.action';
import {
  onClosedProfileSheetDialog,
  onOpenSheetPageProfileDialog,
} from './profile-sheet-dialog.actions';

@Injectable()
export class ProfileSheetDialogEffects {
  @Effect() onClosedProfileSelectedDialog$ = this.actions$.pipe(
    ofType(onClosedProfileSheetDialog),
    map(() => closeProfileSheetDialog())
  );

  @Effect() onOpenSheetPageProfileDialog$ = this.actions$.pipe(
    ofType(onOpenSheetPageProfileDialog),
    map(() => openSheetPageProfileDialog())
  );

  constructor(private actions$: Actions) {}
}
