import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { selectSelectedOrganization } from '../_store/navbar-page.selectors';
import { takeUntil } from 'rxjs/operators';
import { selectSelectedOrganizationLabel } from './_store/book-page.selectors';
import { Organization } from '../_shared/organization/organization';
import { Observable } from 'rxjs';
import { ScrollToService } from '../../_shared/service/widget/scroll/scroll-to.service';
import { DestroyListener } from '../../_shared/service/widget/destroy-listener/destroy-listener';
import { redirectAction } from '../../_store/ama.actions';

@Component({
  selector: 'ama-book-page',
  templateUrl: './book-page.component.html',
  styleUrls: ['./book-page.component.scss'],
})
export class BookPageComponent extends DestroyListener implements OnInit {
  organization: Organization;
  organizationLabel$: Observable<string>;

  constructor(
    private store: Store<any>,
    private scrollToService: ScrollToService
  ) {
    super();
  }

  ngOnInit(): void {
    this.store
      .pipe(select(selectSelectedOrganization), takeUntil(this.destroyed$))
      .subscribe((organization) => {
        this.scrollToService.scrollToTop('#profile-jobs');
        this.organization = organization;
      });
    if (!this.organization) {
      this.store.dispatch(redirectAction({ url: 'home' }));
    }
    this.organizationLabel$ = this.store.pipe(
      select(selectSelectedOrganizationLabel),
      takeUntil(this.destroyed$)
    );
  }
}
