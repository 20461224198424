import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import {
  selectGradeOptions,
  selectJobOptions,
  selectOrganizationOptions,
} from './_store/search.selectors';
import { takeUntil } from 'rxjs/operators';
import { onSearchRequested } from './_store/search.actions';
import { SearchRequest } from './search-request';
import { Observable } from 'rxjs';
import { SelectOption } from '../../../_shared/service/widget/select-option/select-option';
import { DestroyListener } from '../../../_shared/service/widget/destroy-listener/destroy-listener';
import { Router } from '@angular/router';

@Component({
  selector: 'ama-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent extends DestroyListener implements OnInit {
  searchRequest = new SearchRequest();
  organizationOptions: Observable<SelectOption[]>;
  gradeOptions$: Observable<SelectOption[]>;
  jobOptions$: Observable<SelectOption[]>;
  url: string;

  get isDisabled(): boolean {
    return (
      (!this.searchRequest.name || this.searchRequest.name === '') &&
      !this.searchRequest.jobCode &&
      !this.searchRequest.organizationCode &&
      !this.searchRequest.gradeCode
    );
  }

  constructor(private store: Store<any>, private router: Router) {
    super();
  }

  ngOnInit(): void {
    this.url = this.router.routerState.snapshot.url;
    this.jobOptions$ = this.store.pipe(
      select(selectJobOptions),
      takeUntil(this.destroyed$)
    );

    this.organizationOptions = this.store.pipe(
      select(selectOrganizationOptions),
      takeUntil(this.destroyed$)
    );

    this.gradeOptions$ = this.store.pipe(
      select(selectGradeOptions),
      takeUntil(this.destroyed$)
    );
  }

  onSearchClicked() {
    this.store.dispatch(
      onSearchRequested({
        searchRequest: {
          name: this.searchRequest.name,
          organizationCode: this.searchRequest.organizationCode,
          gradeCode: this.searchRequest.gradeCode,
          jobCode: this.searchRequest.jobCode,
        },
        url: this.url,
      })
    );
  }
}
