import { ProfileSheet } from './profile-sheet';
import { Profile } from '../profile/profile';
import { Organization } from '../organization/organization';
import { getJobWithoutHF } from '../job/job.functions';
import { LocalDate } from '../../../_shared/domain/local-date';
import { CodeManagement } from '../code-management/code-management';
import { PROFILE_SHEET } from './profile-sheet.strings';
import {
  getBodyTagColor,
  getFirstAttachmentOrganization,
} from '../organization/organization.functions';
import { AppointmentMode } from '../appointment-mode/appointment-mode';
import { getAppointmentModeLongLabelBisByCode } from '../appointment-mode/appointment-mode.functions';
import { AVATAR_PATH } from '../profile/profile.strings';
import { getGrade } from '../code-management/code-management.functions';
import { Job } from '../job/job';
import { getCareerEvents } from './career/career-event.functions';

export function getProfileSheet(
  profile: Profile,
  organizationChildrenCodes: string[][],
  parentOrganizations: Organization[],
  codeManagement: CodeManagement,
  jobs: Job[],
  appointmentModes: AppointmentMode[],
  detachmentOrganizationCodes: string[]
): ProfileSheet {
  if (profile) {
    const attachmentOrganization = getFirstAttachmentOrganization(
      organizationChildrenCodes,
      parentOrganizations,
      profile.post.organization.code
    );
    return {
      picturePath: AVATAR_PATH.concat(profile.pictureName),
      fullName: profile.lastName.toUpperCase() + ' ' + profile.firstName,
      civility: profile.civility,
      bornDate: profile.bornDate
        ? getBornDateLabel(
            profile.civility,
            new LocalDate(profile.bornDate).getDayMonthYear()
          )
        : '',
      bodyDate: profile.bodyDate
        ? new LocalDate(profile.bodyDate).getDayMonthYear()
        : '',
      bodyAttachmentMode: profile.bodyDate
        ? getAppointmentModeLongLabelBisByCode(
            appointmentModes,
            profile.bodyAttachmentModeCode
          )
        : '',
      organizationLongLabel: attachmentOrganization.longLabel,
      organizationShortLabel: attachmentOrganization.shortLabel,
      job: getJobWithoutHF(profile.job.longLabel),
      grade: getGrade(profile.gradeCode, codeManagement.grades)?.shortLabel,
      gradeDate: profile.gradeDate
        ? new LocalDate(profile.gradeDate).getDayMonthYear()
        : '',
      echelon: profile?.echelonCode,
      echelonDate: profile.echelonDate
        ? new LocalDate(profile.echelonDate).getDayMonthYear()
        : '',
      businessPhone: profile.businessPhone,
      email: profile.email?.toLocaleLowerCase(),
      careerEvents: getCareerEvents(
        profile.careerGrades,
        profile.careerJobs,
        jobs,
        codeManagement,
        organizationChildrenCodes,
        parentOrganizations,
        profile.job.code,
        detachmentOrganizationCodes
      ),
      metadata: {
        bodyTagColor: getBodyTagColor(
          attachmentOrganization.shortLabel.split(' ')[0]
        ),
      },
    };
  }
}

function getBornDateLabel(civility: string, date: string): string {
  return PROFILE_SHEET.BORN_LABEL.replace(
    '{{(e)}}',
    civility === '2' ? 'e' : ''
  ).replace('{{date}}', date);
}
