<div class="container-fluid">
  <div class="row">
    <div class="col-8">
      <div *ngIf="searchTitle$ | async as searchTitle">
        <div *ngIf="searchTitle.items">
          <span class="search-header-text">
            {{ searchTitle.title }}
            <span class="search-element">{{ searchTitle.items }}</span>
          </span>
          <div (click)="onOpenedSearch()" class="d-inline">
            <span class="align-baseline search-edit"
              >Modifier ma recherche</span
            >
            <button class="ama-button ama-button_icon ama-button_icon_small">
              <mat-icon svgIcon="edit">Edit</mat-icon>
            </button>
          </div>
        </div>
        <div *ngIf="!searchTitle.items">
          <span class="d-inline">
            <span *ngIf="searchTitle.allMagistrates" class="search-all">
              {{ searchTitle.title }}
            </span>
            <span *ngIf="!searchTitle.allMagistrates" class="search-all">
              {{ searchTitle.title }}
            </span>
          </span>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-3 number-of-results">
      {{ titleCount$ | async }}
    </div>
  </div>
  <div class="row justify-content-between">
    <div class="col-9">
      <p-virtualScroller
        [itemSize]="155"
        [trackBy]="trackByRow"
        [value]="profileRows$ | async"
        scrollHeight="80vh"
        #virtualRows
      >
        <ng-template let-row pTemplate="item">
          <ama-search-row
            [attr.id]="row.metadata.profileId"
            (click)="onSelectedProfile(row.metadata.profileId)"
            [row]="row"
          ></ama-search-row>
        </ng-template>
      </p-virtualScroller>
    </div>
    <div class="col-3">
      <div class="row">
        <div class="search-results-filters-title">Filtres</div>
      </div>
      <div class="row pb-2">
        <div class="filter-sub-title">Mode de nomination dans le corps</div>
      </div>
      <div class="row mb-1">
        <div
          [class.expanded]="isAreaExpanded()"
          [class.shrunk]="!isAreaExpanded()"
          class="body-nomination-types-list"
        >
          <div
            *ngFor="
              let bodyAppointmentMode of availableBodyAppointmentModes$ | async;
              trackBy: trackByMode
            "
            class="p-field-checkbox"
          >
            <div class="appointment-mode">
              <div class="text-truncate appointment-type-name">
                <p-checkbox
                  (onChange)="onFilterProfileRowsByAppointmentModes()"
                  [(ngModel)]="selectedBodyAppointmentModes"
                  [inputId]="bodyAppointmentMode.code"
                  [value]="bodyAppointmentMode"
                ></p-checkbox>
                <label [for]="bodyAppointmentMode.code">{{
                  bodyAppointmentMode.label
                }}</label>
              </div>
              <div class="appointment-mode-count">
                <span class="p-tag">{{ bodyAppointmentMode.count }}</span>
              </div>
            </div>
          </div>
        </div>
        <div
          (click)="toggleExpandedStatus()"
          *ngIf="(availableBodyAppointmentModes$ | async)?.length > 5"
          class="row pt-2 toggle-view"
        >
          {{ buttonLabel }}
          <mat-icon *ngIf="isAreaExpanded()" svgIcon="expand_less"
            >expand_less
          </mat-icon>
          <mat-icon *ngIf="!isAreaExpanded()" svgIcon="expand_more"
            >expand_more
          </mat-icon>
        </div>
      </div>
      <div class="row filter-sub-title">
        Date de nomination dans le corps
      </div>
      <div class="row">
        <div class="row pb-3 pt-2">
          <div class="pl-3">
            <span class="range-label mr-1">min.</span>
            <span class="range-value">{{ currentMinBodyDateYear }}</span>
          </div>
          <div class="pl-4">
            <span class="range-label mr-1">max.</span>
            <span class="range-value">{{ currentMaxBodyDateYear }}</span>
          </div>
        </div>
        <div class="slider-container">
          <p-slider
            (onChange)="onFilterProfileRowsByAppointmentYears($event)"
            [(ngModel)]="rangeBodyYears"
            [max]="maxBodyDateYear"
            [min]="minBodyDateYear"
            [range]="true"
          ></p-slider>
          <div class="row justify-content-between slider-end-values">
            <span>{{ minBodyDateYear }}</span>
            <span>{{ maxBodyDateYear }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
