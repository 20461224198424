import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

export const API_PATH = 'api/';

export class Resource<T> {
  constructor(protected httpClient: HttpClient, private endPoint: string) {}

  protected get url(): string {
    return API_PATH + this.endPoint;
  }

  getAll(): Observable<T[]> {
    return this.query(null);
  }

  query(params: HttpParams): Observable<T[]> {
    return this.httpClient.get<T[]>(this.url, { params });
  }

  findOne(params: HttpParams): Observable<T> {
    return this.httpClient.get<T>(this.url, { params });
  }

  deleteOne(id: string): Observable<void> {
    return this.httpClient.delete<void>(this.url + '/' + id);
  }

  updateOne(entity: T, params: HttpParams = null): Observable<T> {
    return this.httpClient.put<T>(this.url + '/', entity, { params });
  }

  createOne(entity: T): Observable<T> {
    return this.httpClient.post<T>(this.url + '/', entity);
  }

  createManyByTemplate(entity: T, params: HttpParams): Observable<T[]> {
    return this.httpClient.post<T[]>(this.url + '/', entity, { params });
  }

  createMany(entities: T[]) {
    return this.httpClient.post<T[]>(this.url + '/', entities);
  }

  updateMany(entities: T[]) {
    return this.httpClient.put<T[]>(this.url + '/', entities);
  }
}
