export const HF = '(H/F)';

export const JOB_LABEL = {
  'Président de chambre': 'Présidents de chambre',
  'Président de section': 'Présidents de section',
  Assesseur: 'Présidents assesseurs',
  'Magistrat à la CCSP': 'Magistrats à la CCSP',
  Rapporteur: 'Rapporteurs',
  'Rapporteur public': 'Rapporteurs publics',
  'Vice-président': 'Vice-présidents',
  'Vice-président de section': 'Vice-présidents de section',
};
