import { AppointmentMode } from './appointment-mode';
import { Resource } from '../../../_shared/service/resource/resource';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AppointmentModeService {
  private appointmentModeResource: Resource<AppointmentMode>;

  constructor(private httpClient: HttpClient) {
    this.appointmentModeResource = new Resource<AppointmentMode>(
      httpClient,
      'appointment-modes'
    );
  }

  getAll$(): Observable<AppointmentMode[]> {
    return this.appointmentModeResource.getAll();
  }
}
