import { TypedAction } from '@ngrx/store/src/models';
import { createAction, props } from '@ngrx/store';
import { EnvInfo } from '../_shared/domain/env-info/env-info';

export const effectError = createAction(
  '[API] error',
  props<{ error: any; source: TypedAction<any> }>()
);

export const redirectAction = createAction(
  '[AMA Redirect]',
  props<{ url: string }>()
);

export const setUrlBack = createAction(
  '[AMA set BACK URL]',
  props<{ url: string }>()
);

export const backToHomeAction = createAction('[AMA Logo]');

export const restoreHasLoggedOutAction = createAction(
  '[AMA Login] has logged out false'
);

export const logout = createAction('[AMA Logout] logged out');

export const initialAMA = createAction('[AMA] initial AMA');

export const setEnvironment = createAction(
  '[AMA Env] set environment',
  props<{ environment: EnvInfo }>()
);

export const matomoTrackEvent = createAction(
  '[AMA TrackEvent]',
  props<{ category: string; action: string; name?: string; value?: number }>()
);
