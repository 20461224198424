<div class="search-form">
  <form (ngSubmit)="onSearchClicked()">
    <div class="form-row-inline">
      <div class="form-group">
        <label class="p-float-label" for="name">Nom et/ou Prénom</label>
        <input
          [(ngModel)]="searchRequest.name"
          aria-describedby="nom"
          autocomplete="off"
          class="p-inputtext ama-inputtext"
          id="name"
          name="name"
          type="text"
        />
      </div>

      <div class="form-group">
        <label class="p-float-label" for="organizationCode">Affectation</label>
        <p-dropdown
          [(ngModel)]="searchRequest.organizationCode"
          [filter]="false"
          [options]="organizationOptions | async"
          id="organizationCode"
          name="organizationCode"
          panelStyleClass="ama-dropdown-panel"
          styleClass="ama-dropdown p-dropdown"
        >
        </p-dropdown>
      </div>

      <div class="form-group">
        <label class="p-float-label" for="gradeCode">Grade</label>
        <p-dropdown
          [(ngModel)]="searchRequest.gradeCode"
          [filter]="false"
          [options]="gradeOptions$ | async"
          id="gradeCode"
          name="gradeCode"
          panelStyleClass="ama-dropdown-panel"
          styleClass="ama-dropdown p-dropdown"
        >
        </p-dropdown>
      </div>

      <div class="form-group">
        <label class="p-float-label" for="jobCode">Fonction</label>
        <p-dropdown
          [(ngModel)]="searchRequest.jobCode"
          [filter]="false"
          [options]="jobOptions$ | async"
          id="jobCode"
          name="jobCode"
          panelStyleClass="ama-dropdown-panel"
          styleClass="ama-dropdown p-dropdown"
        >
        </p-dropdown>
      </div>
      <div *ngIf="url !== '/home'" class="form-group form-button">
        <button
          [disabled]="isDisabled"
          class="btn ama-button mt-4"
          mat-button
          type="submit"
        >
          <span>Rechercher</span>
        </button>
      </div>
    </div>
    <div *ngIf="url === '/home'" class="row justify-content-end">
      <button
        [disabled]="isDisabled"
        class="btn ama-button mt-4"
        mat-button
        type="submit"
      >
        <span style="display: flex;">
          <mat-icon svgIcon="search_white"> </mat-icon>
          <span class="button-title">Rechercher</span>
        </span>
      </button>
    </div>
  </form>
</div>
