import { Actions, createEffect, Effect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import {
  catchError,
  filter,
  first,
  map,
  mergeMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import {
  openProfileSheetDialogProfileId,
  openSearchSidebar,
  organizationSelected,
  searchRequested,
} from '../../_store/navbar-page.action';
import {
  onEditSearchSidebar,
  onFilterProfileRowByAppointmentModes,
  onFilterProfileRowsByDateYears,
  onSearchSelectedProfileId,
  restoreSearchResultPageState,
  scrollToRowIndex,
  setScrollIndex,
  updateFilterBodyAppointmentModes,
  updateFilterDateYears,
} from './search-results-page.actions';
import { select, Store } from '@ngrx/store';
import {
  selectCurrentRangeBodyDateYears,
  selectSearchRequestedProfileRows,
} from './search-results-page.selectors';
import { of } from 'rxjs';
import {
  effectError,
  logout,
  matomoTrackEvent,
} from '../../../_store/ama.actions';
import { selectSelectedProfileId } from '../../_store/navbar-page.selectors';

@Injectable()
export class SearchResultsPageEffects {
  @Effect() restoreSearchResultPageState = this.actions$.pipe(
    ofType(logout),
    map(() => restoreSearchResultPageState())
  );

  @Effect() onEditSearchSidebar$ = this.actions$.pipe(
    ofType(onEditSearchSidebar),
    map(() => openSearchSidebar())
  );

  @Effect() onSelectedProfile$ = this.actions$.pipe(
    ofType(onSearchSelectedProfileId),
    map((action) =>
      openProfileSheetDialogProfileId({ profileId: action.profileId })
    )
  );

  @Effect() defaultFilterDateYears$ = this.actions$.pipe(
    ofType(searchRequested),
    mergeMap((action) =>
      this.store.pipe(
        first(),
        select(selectCurrentRangeBodyDateYears),
        map((range) =>
          updateFilterDateYears({ minDate: range[0], maxDate: range[1] })
        ),
        map(() => organizationSelected({ organization: null })),
        catchError((error) => of(effectError({ error, source: action })))
      )
    )
  );

  @Effect() onFilterProfileRowsByDateYears$ = this.actions$.pipe(
    ofType(onFilterProfileRowsByDateYears),
    map((action) =>
      updateFilterDateYears({
        minDate: action.minDate,
        maxDate: action.maxDate,
      })
    )
  );

  @Effect() onFilterProfileRowsByBodyAppointmentModes$ = this.actions$.pipe(
    ofType(onFilterProfileRowByAppointmentModes),
    map((action) =>
      updateFilterBodyAppointmentModes({
        selectedAppointmentModes: action.selectedAppointmentModes,
      })
    )
  );

  onFilterProfileRowByAppointmentModesTrackEvent$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(onFilterProfileRowsByDateYears),
        tap((action) =>
          this.store.dispatch(
            matomoTrackEvent({
              category: 'Page de recherche',
              action: 'Sélectionner une date de nomination dans le corps',
              name: 'min: ' + action.minDate + '  ' + 'max: ' + action.maxDate,
            })
          )
        )
      ),
    { dispatch: false }
  );

  onSelectedProfileTrackEvent$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(onSearchSelectedProfileId),
        tap((action) =>
          this.store.dispatch(
            matomoTrackEvent({
              category: 'Page de recherche',
              action: 'Sélectionner un magistrat',
              name: action.profileId.toString(),
            })
          )
        )
      ),
    { dispatch: false }
  );

  @Effect() scrollToRowIndex$ = this.actions$.pipe(
    ofType(scrollToRowIndex),
    mergeMap((action) =>
      this.store.pipe(
        first(),
        select(selectSelectedProfileId),
        filter((id) => !!id),
        withLatestFrom(
          this.store.pipe(select(selectSearchRequestedProfileRows))
        ),
        map(([selectedProfileId, rows]) =>
          rows.findIndex((r) => r.metadata.profileId === selectedProfileId)
        ),
        map((index) => setScrollIndex({ index })),
        catchError((error) => of(effectError({ error, source: action })))
      )
    )
  );

  constructor(private actions$: Actions, private store: Store<any>) {}
}
