import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AccountService } from '../../core/auth/account.service';
import { map } from 'rxjs/operators';
import { Authority } from './authorities.enum';

const HOME = 'home';
const DEFAULT_HOME = 'login';

@Injectable({
  providedIn: 'root',
})
export class HomeRedirectService implements CanActivate {
  constructor(private router: Router, private accountService: AccountService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.checkAccount();
  }

  checkAccount(): Observable<boolean> {
    return this.accountService.identity().pipe(
      map((account) => {
        if (account) {
          if (account.authorities.find((auth) => Authority[auth])) {
            this.router.navigate([HOME]);
            return true;
          }
        }
        this.router.navigate([DEFAULT_HOME]);
        return true;
      })
    );
  }
}
