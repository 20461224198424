export const NAVBAR = {
  TITLE_TEXT: {
    CAA: "Cours administratives d'appel",
    TA: 'Tribunaux administratifs',
  },
};

export const EXTERNAL_JUDGES_EXCEL_SHEET_LINK =
  'https://intranet.conseil-etat.fr/Media/Intranet/document/DRH/Mobilite_magistrats/annuaire_magistrats_201703';

export const EXTERNAL_JUDGES_LINK_TOOLTIP_MESSAGE =
  "L'annuaire des magistrats en mobilité n'est pas disponible hors VPN";

export const NAVBAR_TRACK_EVENT = {
  CATEGORY: 'Navigation',
  SELECT_ACTION: 'Sélectionner une juridiction',
  LOGOUT_ACTION: 'Déconnexion',
};
