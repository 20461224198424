import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LogoComponent } from './logo/logo.component';
import { AppIconsModule } from './icon/app-icons.module';
import { ErrorComponent } from './error/error.component';
import { ScrollToService } from './scroll/scroll-to.service';
import { ScrollSpyDirective } from './scroll/scroll-spy.directive';
import { LazyImgDirective } from './img/lazy-img.directive';

@NgModule({
  declarations: [
    LogoComponent,
    ErrorComponent,
    ScrollSpyDirective,
    LazyImgDirective,
  ],
  exports: [LogoComponent, ScrollSpyDirective, LazyImgDirective],
  imports: [CommonModule, AppIconsModule],
  providers: [ScrollToService],
})
export class AmaWidgetModule {}
