<div class="row justify-content-end">
  <div class="col-1 pr-0">
    <button
      (click)="onOpenedSearch()"
      class="ama-icon open-search-button float-right"
    >
      <mat-icon svgIcon="search_blue">Recherche</mat-icon>
    </button>
  </div>
</div>
