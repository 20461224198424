import { Actions, createEffect, Effect, ofType } from '@ngrx/effects';
import { map, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { openProfileSheetDialogProfileId } from '../../_store/navbar-page.action';
import {
  onHomeSearchAllMagistrates,
  onHomeSearchAllMagistratesInYear,
  onHomeSelectedProfileId,
} from './home-page.actions';
import { searchFromTemplateRequested } from '../../_shared/search/_store/search.actions';
import { getCurrentYear } from '../home-page.functions';
import { matomoTrackEvent } from '../../../_store/ama.actions';
import { Store } from '@ngrx/store';
import { SEARCH_TRACK_EVENT } from '../../_shared/search/search.strings';
import { HOME_TRACK_EVENT } from '../home-page.strings';

@Injectable()
export class HomePageEffects {
  @Effect() onSelectedProfile$ = this.actions$.pipe(
    ofType(onHomeSelectedProfileId),
    map((action) =>
      openProfileSheetDialogProfileId({ profileId: action.profileId })
    )
  );

  @Effect() onHomeSearchAllMagistrate$ = this.actions$.pipe(
    ofType(onHomeSearchAllMagistrates),
    map(() => searchFromTemplateRequested({ searchRequest: null }))
  );

  onHomeSearchAllMagistrateTrackEvent$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(onHomeSearchAllMagistrates),
        tap(() =>
          this.store.dispatch(
            matomoTrackEvent({
              category: SEARCH_TRACK_EVENT.CATEGORY,
              action: SEARCH_TRACK_EVENT.ALL_MAGISTRATE,
              name: HOME_TRACK_EVENT.replace('{{extend}}', ''),
            })
          )
        )
      ),
    { dispatch: false }
  );

  @Effect() onHomeSearchAllMagistratesInYear$ = this.actions$.pipe(
    ofType(onHomeSearchAllMagistratesInYear),
    map(() =>
      searchFromTemplateRequested({
        searchRequest: {
          name: null,
          organizationCode: null,
          gradeCode: null,
          jobCode: null,
          appointmentYear: getCurrentYear(),
        },
      })
    )
  );

  onHomeSearchAllMagistratesInYearTrackEvent$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(onHomeSearchAllMagistratesInYear),
        tap((act) =>
          this.store.dispatch(
            matomoTrackEvent({
              category: SEARCH_TRACK_EVENT.CATEGORY,
              action: SEARCH_TRACK_EVENT.MAGISTRATE_YEAR.replace(
                '{{year}}',
                getCurrentYear()
              ),
              name: HOME_TRACK_EVENT.replace(
                '{{extend}}',
                act.inCarousel ? '- Card' : '- Voir tout'
              ),
            })
          )
        )
      ),
    { dispatch: false }
  );

  onSelectedProfileTrackEvent$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(onHomeSelectedProfileId),
        tap((action) =>
          this.store.dispatch(
            matomoTrackEvent({
              category: 'Carousel',
              action: 'Sélectionner un magistrat',
              name: action.profileId.toString(),
            })
          )
        )
      ),
    { dispatch: false }
  );

  constructor(private actions$: Actions, private store: Store<any>) {}
}
