import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';
import { navbarRoutes } from './navbar-page/navbar.route';
import { errorRoute } from './_shared/service/widget/error/error.route';
import { loginRoute } from './login/login.route';

const routerOptions: ExtraOptions = {
  useHash: true,
  scrollPositionRestoration: 'enabled',
  anchorScrolling: 'enabled',
};
const routes: Routes = [loginRoute, ...navbarRoutes, ...errorRoute];

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
