import { Injectable } from '@angular/core';

declare var window: {
  [key: string]: any;
  prototype: Window;
  new (): Window;
};

@Injectable()
export class MatomoInjector {
  constructor() {
    window._paq = window._paq || [];
  }

  /**
   * Injects the Matomo tracker in the DOM.
   *
   * @param env: environment application.
   * @param id : id for this application/site.
   * @memberof MatomoInjector
   */
  init(env: string, id: number) {
    window._paq.push([
      'setCookieDomain',
      '*.annuaire-magistrats' + env + 'conseil-etat.fr',
    ]);
    window._paq.push(['trackPageView']);
    window._paq.push(['enableLinkTracking']);
    (() => {
      const u = 'https://stats.conseil-etat.fr/';
      window._paq.push(['setTrackerUrl', u + 'matomo.php']);
      window._paq.push(['setSiteId', id.toString()]);
      const d = document,
        g = d.createElement('script'),
        s = d.getElementsByTagName('script')[0];
      g.type = 'text/javascript';
      g.async = true;
      g.defer = true;
      g.src = u + 'matomo.js';
      s.parentNode.insertBefore(g, s);
    })();
  }
}
