import { createFeatureSelector, createSelector } from '@ngrx/store';
import { BookPageStates } from '../../_store/book-page.states';
import { selectSelectedProfileJobs } from '../../_store/book-page.selectors';
import { JOB_LABEL } from '../../../_shared/job/job.strings';

const selectFeature = createFeatureSelector<BookPageStates>('BOOK-PAGE');

export const selectActiveJobCode = createSelector(
  selectFeature,
  (state: BookPageStates) => state.activeJobCode
);

//JOB
export const selectSelectedProfileDistinctJobs = createSelector(
  selectSelectedProfileJobs,
  (jobs) =>
    jobs.map((j) => ({
      code: j.code,
      label: JOB_LABEL[j.label.trim()] ? JOB_LABEL[j.label.trim()] : j.label,
    }))
);
