<p-dialog
  position="right"
  [draggable]="false"
  [closable]="false"
  [visible]="isVisible$ | async"
  [style]="{ width: '400px' }"
  styleClass="col-3 h-100"
  transitionOptions="250ms"
>
  <p-header>
    <div class="mt-2">
      <button
        (click)="openSheetPage()"
        class="ama-button ama-button_icon"
        type="button"
      >
        <mat-icon svgIcon="en_large">Agrandir</mat-icon>
      </button>
      <button
        (click)="onClosedProfileDialog()"
        class="ama-button ama-button_icon"
        type="button"
      >
        <mat-icon svgIcon="close">Close</mat-icon>
      </button>
    </div>
  </p-header>
  <div
    *ngIf="profileSheet$ | async as sheet"
    class="container-fluid h-100"
    id="sheet-dialog-container"
  >
    <div class="col-12 no-gutters">
      <div class="avatar row">
        <img [src]="sheet.picturePath" alt="avatar" />
      </div>
      <div class="profile-name row justify-content-center text-center pt-2">
        <span>{{ sheet.fullName }}</span>
      </div>
      <div class="row justify-content-center text-center">
        <div class="profile-born col-6">
          <span>{{ sheet.bornDate }}</span>
        </div>
      </div>
      <div class="col-12 align-self-center mt-4 profile-contact">
        <div class="row justify-content-center">
          <span>{{ sheet.businessPhone }}</span>
        </div>
        <div class="row justify-content-center">
          <span
            ><a href="mailto:{{ sheet.email }}">{{ sheet.email }}</a></span
          >
        </div>
      </div>
      <div class="profile-details flex-column pt-4">
        <div class="row justify-content-between mb-1">
          <div class="col-8">
            <span>Date d'entrée dans le corps</span>
          </div>
          <div class="col-4 text-right">
            <span>{{ sheet.bodyDate }}</span>
          </div>
        </div>
        <div class="row justify-content-between mb-1">
          <div class="col-6">
            <span>Mode de nomination</span>
          </div>
          <div class="col-6 text-truncate text-right">
            <span
              pTooltip="{{ sheet.bodyAttachmentMode }}"
              tooltipPosition="top"
              >{{ sheet.bodyAttachmentMode }}
            </span>
          </div>
        </div>
        <hr />
        <div class="row justify-content-between mb-1">
          <div class="col-2">
            <span>Affectation</span>
          </div>
          <div class="col-10 blue-text text-right">
            <span>{{ sheet.organizationLongLabel }}</span>
          </div>
        </div>
        <div class="row justify-content-between mb-1">
          <div class="col-3">
            <span>Fonction</span>
          </div>
          <div class="col-9 text-right">
            <span>{{ sheet.job }}</span>
          </div>
        </div>
        <hr />
        <div class="row justify-content-between mb-1">
          <div class="col-3">
            <span>Grade</span>
          </div>
          <div class="col-9 blue-text text-right">
            <span>{{ sheet.grade }}</span>
          </div>
        </div>
        <div class="row justify-content-between mb-1">
          <div class="col-8">
            <span>Date de nomination dans le grade</span>
          </div>
          <div class="col-4 text-right">
            <span>{{ sheet.gradeDate }}</span>
          </div>
        </div>
        <hr />
        <div class="row justify-content-between mb-1">
          <div class="col-8">
            <span>Échelon</span>
          </div>
          <div class="col-4 blue-text text-right">
            <span>{{ sheet.echelon }}</span>
          </div>
        </div>
        <div class="row justify-content-between mb-1">
          <div class="col-8">
            <span>Date d'entrée dans l'échelon</span>
          </div>
          <div class="col-4 text-right">
            <span>{{ sheet.echelonDate }}</span>
          </div>
        </div>
        <ama-profile-sheet-career
          [careerEvents]="sheet.careerEvents"
        ></ama-profile-sheet-career>
      </div>
    </div>
  </div>
</p-dialog>
