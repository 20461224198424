<div id="profile-sheet-page" class="profile-sheet-page my-3">
  <div *ngIf="profileSheet$ | async as sheet" class="mt-2">
    <div class="row">
      <div class="col-3 avatar text-center">
        <img [src]="sheet.picturePath" alt="avatar" />
      </div>
      <div class="col-7 d-flex align-items-start flex-column">
        <div class="mb-auto">
          <div class="row profile-name">
            <span>{{ sheet.fullName }}</span>
          </div>
          <div class="row profile-born">
            <span>{{ sheet.bornDate }}</span>
          </div>
          <div class="row profile-organization mt-3 align-items-center">
            <span
              class="organization-tag mr-2"
              [style.background]="sheet.metadata.bodyTagColor.backgroundColor"
              [style.color]="sheet.metadata.bodyTagColor.textColor"
              >{{ sheet.organizationShortLabel }}</span
            >
            <span class="profile-job">{{ sheet.job }}</span>
          </div>
        </div>
        <div class="row profile-phone">
          <span>{{ sheet.businessPhone }}</span>
        </div>
        <div class="row">
          <span>
            <a class="profile-email" href="mailto:{{ sheet.email }}">{{
              sheet.email
            }}</a></span
          >
        </div>
      </div>
      <div class="print-block col-2">
        <button
          [useExistingCss]="true"
          printSectionId="profile-sheet-page"
          ngxPrint
          class="btn ama-button ama-button_reverse float-right"
        >
          <span style="display: flex;">
            <mat-icon svgIcon="print">Imprimer</mat-icon>
            <span class="print-text">Imprimer</span>
          </span>
        </button>
      </div>
    </div>
    <div class="sheet-block row mt-5 p-4">
      <div
        class="col-4 block-nomination px-4 d-flex align-items-start flex-column"
      >
        <div class="mb-auto">
          <div class="row">
            <span class="sheet-block-title"
              >Mode de nomination dans le corps</span
            >
          </div>
          <div class="row">
            <span class="sheet-block-contains">
              {{ sheet.bodyAttachmentMode }}
            </span>
          </div>
        </div>
        <div class="row">
          <span class="sheet-block-title">Date d'entrée dans le corps</span>
        </div>
        <div class="row">
          <span class="sheet-block-contains">
            {{ sheet.bodyDate }}
          </span>
        </div>
      </div>
      <div class="col-4 block-grade px-5 d-flex align-items-start flex-column">
        <div class="mb-auto">
          <div class="row">
            <span class="sheet-block-title">Grade</span>
          </div>
          <div class="row">
            <span class="sheet-block-contains">{{ sheet.grade }}</span>
          </div>
        </div>
        <div class="row">
          <span class="sheet-block-title">Date d'entrée dans le grade</span>
        </div>
        <div class="row">
          <span class="sheet-block-contains">
            {{ sheet.gradeDate }}
          </span>
        </div>
      </div>
      <div
        class="col-4 block-echelon px-5 d-flex align-items-start flex-column"
      >
        <div class="mb-auto">
          <div class="row">
            <span class="sheet-block-title">Echelon</span>
          </div>
          <div class="row">
            <span class="sheet-block-contains">{{ sheet.echelon }}</span>
          </div>
        </div>
        <div class="row">
          <span class="sheet-block-title">Date d'entrée dans l'échelon</span>
        </div>
        <div class="row">
          <span class="sheet-block-contains">
            {{ sheet.echelonDate }}
          </span>
        </div>
      </div>
    </div>
    <div class="sheet-career col-4 pb-5">
      <ama-profile-sheet-career
        [careerEvents]="sheet.careerEvents"
      ></ama-profile-sheet-career>
    </div>
  </div>
</div>
