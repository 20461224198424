import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MATOMO_ENV, MATOMO_IDS } from './matomo.strings';
import { MatomoInjector } from './matomo-injector.service';

@Injectable({
  providedIn: 'root',
})
export class MatomoService {
  constructor(
    private httpClient: HttpClient,
    private matomoInjector: MatomoInjector
  ) {}

  init(env: string): void {
    if (MATOMO_IDS[env]) {
      this.matomoInjector.init(MATOMO_ENV[env], MATOMO_IDS[env]);
    }
  }
}
