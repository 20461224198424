import { Routes } from '@angular/router';
import { NavbarPageComponent } from './navbar-page.component';
import { bookPageRoutes } from './book-page/book-page.route';
import { HomeRedirectService } from '../_shared/service/security/home-redirect.service';
import { searchResultsPageRoutes } from './search-results-page/search-results-page.route';
import { Authority } from '../_shared/service/security/authorities.enum';
import { UserRouteAccessService } from '../_shared/service/security/user-route-access.service';
import { homePageRoutes } from './home-page/home-page.route';
import { profileSheetPageRoutes } from './profile-sheet-page/profile-sheet-page.route';

export const navbarRoutes: Routes = [
  {
    path: '',
    component: NavbarPageComponent,
    data: {
      authorities: [Authority.ROLE_USER],
    },
    canActivate: [UserRouteAccessService],
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: NavbarPageComponent,
        canActivate: [HomeRedirectService],
      },
      ...homePageRoutes,
      ...bookPageRoutes,
      ...searchResultsPageRoutes,
      ...profileSheetPageRoutes,
    ],
  },
];
