import { Action, createReducer, on } from '@ngrx/store';
import {
  initialSearchResultsPageStates,
  SearchResultsPageStates,
} from './search-results-page.states';
import {
  resetSearchResultPageState,
  restoreSearchResultPageState,
  setScrollIndex,
  updateFilterBodyAppointmentModes,
  updateFilterDateYears,
} from './search-results-page.actions';
import { searchRequested } from '../../_store/navbar-page.action';

const reducer = createReducer(
  initialSearchResultsPageStates,
  on(restoreSearchResultPageState, () => initialSearchResultsPageStates),
  on(updateFilterDateYears, (state, action) => ({
    ...state,
    minDate: action.minDate,
    maxDate: action.maxDate,
  })),
  on(updateFilterBodyAppointmentModes, (state, action) => ({
    ...state,
    selectedBodyAppointmentModes: action.selectedAppointmentModes,
  })),
  on(searchRequested, (state) => ({
    ...state,
    minDate: initialSearchResultsPageStates.minDate,
    maxDate: initialSearchResultsPageStates.maxDate,
  })),
  on(resetSearchResultPageState, (state) => ({
    ...state,
    minDate: initialSearchResultsPageStates.minDate,
    maxDate: initialSearchResultsPageStates.maxDate,
    selectedBodyAppointmentModes:
      initialSearchResultsPageStates.selectedBodyAppointmentModes,
    scrollToIndex: 0,
  })),
  on(setScrollIndex, (state, action) => ({
    ...state,
    scrollToIndex: action.index,
  }))
);

export function SearchResultsPageReducer(
  state: SearchResultsPageStates | undefined,
  action: Action
) {
  return reducer(state, action);
}
