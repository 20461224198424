import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { scrollToJob } from './job-anchor.actions';
import { ScrollToService } from '../../../../_shared/service/widget/scroll/scroll-to.service';
import { matomoTrackEvent } from '../../../../_store/ama.actions';
import { Store } from '@ngrx/store';

@Injectable()
export class JobAnchorEffects {
  scrollToJob = createEffect(
    () =>
      this.actions$.pipe(
        ofType(scrollToJob),
        tap((action) => this.scrollToService.scrollToById(action.code))
      ),
    { dispatch: false }
  );

  scrollToJobTrackEvent$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(scrollToJob),
        tap((action) =>
          this.store.dispatch(
            matomoTrackEvent({
              category: 'Ancre Fonction',
              action: "Scroll jusqu'à la fonction",
              name: action.code,
            })
          )
        )
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private scrollToService: ScrollToService,
    private store: Store<any>
  ) {}
}
