import { Action, createReducer, on } from '@ngrx/store';
import { AmaStates, initialAmaStates } from './ama.states';
import {
  logout,
  restoreHasLoggedOutAction,
  setEnvironment,
  setUrlBack,
} from './ama.actions';

const reducer = createReducer(
  initialAmaStates,
  on(logout, (state) => ({
    ...state,
    hasLoggedOut: true,
  })),
  on(restoreHasLoggedOutAction, (state) => ({
    ...state,
    hasLoggedOut: false,
  })),
  on(setEnvironment, (state, action) => ({
    ...state,
    environment: action.environment,
  })),
  on(setUrlBack, (state, action) => ({
    ...state,
    backUrl: action.url,
  }))
);

export function AmaReducer(state: AmaStates | undefined, action: Action) {
  return reducer(state, action);
}
