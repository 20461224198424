import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { LoginService } from '../_shared/core/login/login.service';
import { Authority } from '../_shared/service/security/authorities.enum';
import { select, Store } from '@ngrx/store';
import { DestroyListener } from '../_shared/service/widget/destroy-listener/destroy-listener';
import { takeUntil } from 'rxjs/operators';
import { selectHasLoggedOut } from '../_store/ama.selectors';
import { Observable } from 'rxjs';
import {
  matomoTrackEvent,
  restoreHasLoggedOutAction,
} from '../_store/ama.actions';
import { LOGIN_TRACK_EVENT } from './login.strings';

const UNAUTHORIZED_ERROR = 'UNAUTHORIZED_ERROR';
const IDENTITY_ERROR = 'IDENTITY_ERROR';

@Component({
  selector: 'ama-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent extends DestroyListener implements OnInit {
  loginForm = this.formBuilder.group({
    username: [''],
    password: [''],
    rememberMe: [false],
  });
  error = null;
  hasLoggedOut$: Observable<boolean>;

  constructor(
    private formBuilder: FormBuilder,
    private loginService: LoginService,
    private store: Store<any>
  ) {
    super();
  }

  login(): void {
    this.store.dispatch(restoreHasLoggedOutAction());
    this.loginService
      .login({
        username: this.loginForm.get('username')!.value,
        password: this.loginForm.get('password')!.value,
        rememberMe: this.loginForm.get('rememberMe')!.value,
      })
      .subscribe(
        (account) => {
          if (!account.authorities.includes(Authority.ROLE_USER)) {
            this.error = UNAUTHORIZED_ERROR;
            this.store.dispatch(
              matomoTrackEvent({
                category: LOGIN_TRACK_EVENT.CATEGORY,
                action: LOGIN_TRACK_EVENT.ACTION.NAME,
                name: LOGIN_TRACK_EVENT.ACTION.UNAUTHORIZED,
              })
            );
          } else {
            this.store.dispatch(
              matomoTrackEvent({
                category: LOGIN_TRACK_EVENT.CATEGORY,
                action: LOGIN_TRACK_EVENT.ACTION.NAME,
                name: LOGIN_TRACK_EVENT.ACTION.SUCCESS,
              })
            );
          }
        },
        () => {
          this.error = IDENTITY_ERROR;
          this.store.dispatch(
            matomoTrackEvent({
              category: LOGIN_TRACK_EVENT.CATEGORY,
              action: LOGIN_TRACK_EVENT.ACTION.NAME,
              name: LOGIN_TRACK_EVENT.ACTION.ERROR,
            })
          );
        }
      );
  }

  ngOnInit(): void {
    this.hasLoggedOut$ = this.store.pipe(
      select(selectHasLoggedOut),
      takeUntil(this.destroyed$)
    );
  }
}
