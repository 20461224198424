import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { selectTotalProfiles } from '../../_store/navbar-page.selectors';
import { takeUntil } from 'rxjs/operators';
import { onHomeSearchAllMagistrates } from '../_store/home-page.actions';
import { Observable } from 'rxjs';
import { ProfileRow } from '../../_shared/profile-row/profile-row';
import { DestroyListener } from '../../../_shared/service/widget/destroy-listener/destroy-listener';

@Component({
  selector: 'ama-magistrate-stats',
  templateUrl: './magistrate-stats.component.html',
  styleUrls: ['./magistrate-stats.component.scss'],
})
export class MagistrateStatsComponent extends DestroyListener
  implements OnInit {
  currentYear: number;
  featuredProfiles$: Observable<ProfileRow[]>;
  totalProfiles$: Observable<string>;

  constructor(private store: Store<any>) {
    super();
  }

  ngOnInit() {
    this.totalProfiles$ = this.store.pipe(
      select(selectTotalProfiles),
      takeUntil(this.destroyed$)
    );
  }

  onSearchAllMagistrates() {
    this.store.dispatch(onHomeSearchAllMagistrates());
  }
}
