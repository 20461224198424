import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { filter, first, switchMap, tap } from 'rxjs/operators';
import { onDestroySheetPage } from './profile-sheet-page.actions';
import { selectCurrentUrl } from '../../../_store/ama.selectors';
import { openProfileSheetDialog } from '../../_store/navbar-page.action';

@Injectable()
export class ProfileSheetPageEffects {
  @Effect() defaultFilterDateYears$ = this.actions$.pipe(
    ofType(onDestroySheetPage),
    switchMap(() =>
      this.store.pipe(
        first(),
        select(selectCurrentUrl),
        filter((url) => url === 'login'),
        tap(() => openProfileSheetDialog())
      )
    )
  );
  constructor(private actions$: Actions, private store: Store<any>) {}
}
