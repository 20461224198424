import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchResultsPageComponent } from './search-results-page.component';
import { MatIconModule } from '@angular/material/icon';
import { VirtualScrollerModule } from 'primeng/virtualscroller';
import { SliderModule } from 'primeng/slider';
import { FormsModule } from '@angular/forms';
import { CheckboxModule } from 'primeng/checkbox';
import { ButtonModule } from 'primeng/button';
import { SearchResultsPageStoreModule } from './_store/module';
import { MatSliderModule } from '@angular/material/slider';
import { SearchRow } from './search-row/search-row.component';
import { SearchModule } from '../_shared/search/search.module';

@NgModule({
  declarations: [SearchResultsPageComponent, SearchRow],
  imports: [
    CommonModule,
    MatIconModule,
    VirtualScrollerModule,
    SliderModule,
    FormsModule,
    CheckboxModule,
    ButtonModule,
    SearchResultsPageStoreModule,
    MatSliderModule,
    SearchModule,
  ],
})
export class SearchResultsPageModule {}
