import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

@NgModule({})
export class AppIconsModule {
  constructor(iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
    iconRegistry.addSvgIcon(
      'search_blue',
      sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/icons/search_blue.svg'
      )
    );
    iconRegistry.addSvgIcon(
      'close',
      sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/close.svg')
    );
    iconRegistry.addSvgIcon(
      'edit',
      sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/edit.svg')
    );
    iconRegistry.addSvgIcon(
      'expand_less',
      sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/icons/expand_less.svg'
      )
    );
    iconRegistry.addSvgIcon(
      'expand_more',
      sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/icons/expand_more.svg'
      )
    );
    iconRegistry.addSvgIcon(
      'search_white',
      sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/icons/search_white.svg'
      )
    );
    iconRegistry.addSvgIcon(
      'logout',
      sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/logout.svg')
    );
    iconRegistry.addSvgIcon(
      'greater_than',
      sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/icons/greater_than.svg'
      )
    );
    iconRegistry.addSvgIcon(
      'suit_case_assignment',
      sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/icons/suit_case_assignment.svg'
      )
    );
    iconRegistry.addSvgIcon(
      'arrow_grade',
      sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/icons/arrow_grade.svg'
      )
    );
    iconRegistry.addSvgIcon(
      'en_large',
      sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/icons/en_large.svg'
      )
    );
    iconRegistry.addSvgIcon(
      'page_back',
      sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/icons/page_back.svg'
      )
    );
    iconRegistry.addSvgIcon(
      'detachment',
      sanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/icons/detachment.svg'
      )
    );
    iconRegistry.addSvgIcon(
      'print',
      sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/print.svg')
    );
  }
}
