import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProfileSheetDialogComponent } from './profile-sheet-dialog.component';
import { ProfileSheetDialogStoreModule } from './_store/module';
import { DialogModule } from 'primeng/dialog';
import { MatIconModule } from '@angular/material/icon';
import { TooltipModule } from 'primeng/tooltip';
import { ProfileSheetCareerModule } from '../_shared/profile-sheet/career/profile-sheet-career.module';

@NgModule({
  declarations: [ProfileSheetDialogComponent],
  imports: [
    CommonModule,
    ProfileSheetDialogStoreModule,
    DialogModule,
    MatIconModule,
    TooltipModule,
    ProfileSheetCareerModule,
  ],
  exports: [ProfileSheetDialogComponent],
})
export class ProfileSheetDialogModule {}
