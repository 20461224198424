import { HF, JOB_LABEL } from './job.strings';
import { Job } from './job';

export function getJobLabel(label: string): string {
  if (!label) return;
  const format = getJobWithoutHF(label);
  return JOB_LABEL[format] ?? format;
}

export function getJobWithoutHF(label: string): string {
  if (!label) return;
  return label.replace(HF, '').trim();
}

export function getJob(code: string, jobs: Job[]): Job {
  return jobs.find((job) => job.code === code);
}
