<div class="search-row-container">
  <div class="search-row">
    <div class="card-picture">
      <img [src]="row.picturePath" alt="avatar" class="img-responsive" />
    </div>
    <div>
      <h5 class="row-name">{{ row.displayName }}</h5>
      <div class="row-profile-details">
        <div class="row-job">{{ row.jobLabel }}</div>
        <div class="row-appointment-date">{{ row.gradeLabel }}</div>
        <div class="row-contact-info">
          <span
            ><a href="mailto:{{ row.emailLabel }}">{{
              row.emailLabel
            }}</a></span
          >
          <span *ngIf="row.phoneLabel != ''"> | </span>
          <span>{{ row.phoneLabel }}</span>
        </div>
      </div>
    </div>
    <div>
      <div
        [style.background]="row.metadata.bodyTagColor.backgroundColor"
        class="organization-tag row-tag"
      >
        <span [style.color]="row.metadata.bodyTagColor.textColor">{{
          row.attachmentOrganizationName
        }}</span>
      </div>
    </div>
  </div>
</div>
