import { OwlOptions } from 'ngx-owl-carousel-o';

export const OWL_CAROUSEL_OPTIONS: OwlOptions = {
  stagePadding: 5,
  nav: true,
  margin: 20,
  navText: [
    "<div class='nav-btn prev-slide'></div>",
    "<div class='nav-btn next-slide'></div>",
  ],
  responsive: {
    0: {
      items: 1,
    },
    500: {
      items: 2,
    },

    700: {
      items: 2,
    },

    900: {
      items: 3,
    },

    1200: {
      items: 4,
    },
    1500: {
      items: 5,
    },
    1800: {
      items: 6,
    },
    2100: {
      items: 7,
    },
  },
};
