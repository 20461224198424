import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { DestroyListener } from '../../../_shared/service/widget/destroy-listener/destroy-listener';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  selectActiveJobCode,
  selectSelectedProfileDistinctJobs,
} from './_store/job-anchor.selectors';
import { scrollToJob } from './_store/job-anchor.actions';

@Component({
  selector: 'ama-job-anchor',
  templateUrl: './job-anchor.component.html',
  styleUrls: ['./job-anchor.component.scss'],
})
export class JobAnchorComponent extends DestroyListener implements OnInit {
  distinctJobs$: Observable<{ code: string; label: string }[]>;
  activeJobCode: string;

  constructor(private store: Store<any>) {
    super();
  }

  ngOnInit(): void {
    this.distinctJobs$ = this.store.pipe(
      select(selectSelectedProfileDistinctJobs),
      takeUntil(this.destroyed$)
    );
    this.store
      .pipe(select(selectActiveJobCode), takeUntil(this.destroyed$))
      .subscribe((code) => (this.activeJobCode = code));
  }

  scrollToJob(code: string) {
    this.store.dispatch(scrollToJob({ code }));
  }
}
