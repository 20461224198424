<div *ngIf="organizationLabel$ | async" class="container-fluid mt-3 h-100">
  <div class="row">
    <div class="col-12 organization-title">
      <span>{{ organizationLabel$ | async }}</span>
    </div>
  </div>
  <div class="row justify-content-between book-page">
    <div class="col-9">
      <ama-profile-jobs></ama-profile-jobs>
    </div>
    <div class="col-3">
      <ama-job-anchor></ama-job-anchor>
    </div>
  </div>
</div>
