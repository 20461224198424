import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { DestroyListener } from '../../_shared/service/widget/destroy-listener/destroy-listener';
import { BACK_BUTTON_URLS } from '../../app.constants';
import { selectBackUrl, selectCurrentUrl } from '../../_store/ama.selectors';
import { Observable } from 'rxjs';

@Component({
  selector: 'ama-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss'],
})
export class TopBarComponent extends DestroyListener implements OnInit {
  url: string;
  backUrl$: Observable<string>;
  backButtonUrls = BACK_BUTTON_URLS;

  constructor(private store: Store<any>) {
    super();
  }

  ngOnInit(): void {
    this.store
      .pipe(select(selectCurrentUrl), takeUntil(this.destroyed$))
      .subscribe((url) => (this.url = url));
    this.backUrl$ = this.store.pipe(
      select(selectBackUrl),
      takeUntil(this.destroyed$)
    );
  }
}
