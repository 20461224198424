<div *ngIf="careerEvents.length > 0" class="container-fluid my-5">
  <span class="career-title">Parcours</span>
  <div class="mt-4">
    <p-timeline [value]="careerEvents" class="no-gutters">
      <ng-template let-event pTemplate="marker">
        <span class="ama-icon marker">
          <mat-icon [svgIcon]="event.icon">Event icon</mat-icon>
        </span>
      </ng-template>
      <ng-template let-event pTemplate="opposite">
        <div class="career-dates">
          <span>{{ event.dates }}</span>
        </div>
      </ng-template>
      <ng-template let-event pTemplate="content">
        <div class="career-assignment-content">
          <span
            [ngClass]="{ grade: event.type === 1 }"
            class="career-assignment"
            >{{ event.assignment }}</span
          >
          <span *ngIf="event.organization" class="career-assignment-org">{{
            event.organization
          }}</span>
        </div>
      </ng-template>
    </p-timeline>
  </div>
</div>
