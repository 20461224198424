export function compareUsingProperties<T, K extends keyof T>(
  a: T,
  b: T,
  propertyNames: K[]
): number {
  let res = compareNullables(a, b);
  if (null !== res) {
    return res;
  }
  for (let propertyName of propertyNames) {
    res = compareNullables(a[propertyName], b[propertyName]);
    if (null !== res) {
      return res;
    }
  }
  return 0;
}

function compareNullables(a: any, b: any): number | null {
  if (a === b) {
    return 0;
  }
  if (a === null || typeof a === 'undefined') {
    return 1;
  }
  if (b === null || typeof b === 'undefined') {
    return -1;
  }
  if (typeof a === 'string' && typeof b === 'string') {
    return a.localeCompare(b);
  }
  return null;
}
