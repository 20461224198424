import { Injectable } from '@angular/core';
import { Actions, createEffect, Effect, ofType } from '@ngrx/effects';
import { map, tap } from 'rxjs/operators';
import {
  closeSearchSidebar,
  openSearchSidebar,
  searchRequested,
} from '../../../_store/navbar-page.action';
import {
  onClosedSearchSidebar,
  onOpenedSearchSidebar,
  onSearchRequested,
  searchFromTemplateRequested,
} from './search.actions';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import {
  matomoTrackEvent,
  redirectAction,
} from '../../../../_store/ama.actions';
import { SEARCH_TRACK_EVENT } from '../search.strings';

@Injectable()
export class SearchEffects {
  @Effect() onOpenSearchSidebar$ = this.actions$.pipe(
    ofType(onOpenedSearchSidebar),
    map(() => openSearchSidebar())
  );

  @Effect() onCloseSearchSidebar$ = this.actions$.pipe(
    ofType(onClosedSearchSidebar),
    map(() => closeSearchSidebar())
  );

  @Effect() searchFromTemplateRequested$ = this.actions$.pipe(
    ofType(searchFromTemplateRequested),
    tap(() => this.store.dispatch(redirectAction({ url: 'search' }))),
    tap((action) =>
      this.store.dispatch(
        searchRequested({ searchRequested: action.searchRequest })
      )
    ),
    map(() => onClosedSearchSidebar())
  );

  onSearchRequestedTrackEvent$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(onSearchRequested),
        tap((action) =>
          this.store.dispatch(
            searchFromTemplateRequested({ searchRequest: action.searchRequest })
          )
        ),
        tap((action) =>
          this.store.dispatch(
            matomoTrackEvent({
              category: SEARCH_TRACK_EVENT.CATEGORY,
              action: SEARCH_TRACK_EVENT.START_SEARCH,
              name: action.url,
            })
          )
        )
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private router: Router,
    private store: Store<any>
  ) {}
}
