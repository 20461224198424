import { BodyAppointmentMode } from './body-appointment-mode';
import { Profile } from '../../_shared/profile/profile';
import { AppointmentMode } from '../../_shared/appointment-mode/appointment-mode';
import { distinctValues } from '../../../_shared/functions/arrays.functions';
import { getAppointmentModeLongLabelBisByCode } from '../../_shared/appointment-mode/appointment-mode.functions';

export function getBodyAppointmentModeByProfile(
  profiles: Profile[],
  appointmentModes: AppointmentMode[]
): BodyAppointmentMode[] {
  return distinctValues(
    profiles.slice().map((profile) => profile.bodyAttachmentModeCode)
  ).reduce(
    (
      bodyAppointmentMode: BodyAppointmentMode[],
      appointmentModeCode: string
    ) => {
      bodyAppointmentMode.push({
        code: appointmentModeCode,
        label: getAppointmentModeLongLabelBisByCode(
          appointmentModes,
          appointmentModeCode
        ),
        count: profiles
          .filter(
            (profile) => profile.bodyAttachmentModeCode === appointmentModeCode
          )
          .length.toLocaleString(),
      });
      return bodyAppointmentMode;
    },
    []
  );
}
