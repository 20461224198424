import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import {
  selectEnvironment,
  selectIsProductionEnv,
} from '../_store/ama.selectors';
import { DestroyListener } from '../_shared/service/widget/destroy-listener/destroy-listener';
import { takeUntil } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Component({
  selector: 'ama-page-ribbon',
  templateUrl: './page-ribbon.component.html',
  styleUrls: ['./page-ribbon.component.scss'],
})
export class PageRibbonComponent extends DestroyListener implements OnInit {
  profilesStr: string;
  env$: Observable<string>;
  isProduction$: Observable<boolean>;

  constructor(private store: Store<any>) {
    super();
  }

  ngOnInit() {
    this.env$ = this.store.pipe(
      select(selectEnvironment),
      takeUntil(this.destroyed$)
    );
    this.isProduction$ = this.store.pipe(
      select(selectIsProductionEnv),
      takeUntil(this.destroyed$)
    );
  }
}
