import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputTextModule } from 'primeng/inputtext';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { HomePageComponent } from './home-page.component';
import { DropdownModule } from 'primeng/dropdown';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { MatIconModule } from '@angular/material/icon';
import { HomePageStoreModule } from './_store/module';
import { CardModule } from 'primeng/card';
import { MagistrateCarouselComponent } from './magistrate-carousel/magistrate-carousel.component';
import { MagistrateStatsComponent } from './magistrate-stats/magistrate-stats.component';
import { SearchModule } from '../_shared/search/search.module';

@NgModule({
  declarations: [
    HomePageComponent,
    MagistrateCarouselComponent,
    MagistrateStatsComponent,
  ],
  imports: [
    CommonModule,
    InputTextModule,
    FormsModule,
    ButtonModule,
    ReactiveFormsModule,
    DropdownModule,
    SearchModule,
    CarouselModule,
    MatIconModule,
    HomePageStoreModule,
    CardModule,
  ],
  exports: [HomePageComponent],
})
export class HomePageModule {}
