import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Job } from './job';
import { Resource } from '../../../_shared/service/resource/resource';
import { getJobLabel } from './job.functions';

@Injectable({
  providedIn: 'root',
})
export class JobService {
  private jobResource: Resource<Job>;

  constructor(private httpClient: HttpClient) {
    this.jobResource = new Resource<Job>(httpClient, 'jobs');
  }

  getAll$(): Observable<Job[]> {
    return this.jobResource.getAll().pipe(
      map((jobs) =>
        jobs.map((job) => ({ ...job, longLabel: getJobLabel(job.longLabel) }))
      ),
      map((o) => o.sort((a, b) => a.weight - b.weight))
    );
  }
}
