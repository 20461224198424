import { Component, Input, OnInit } from '@angular/core';
import { CareerEvent } from './career-event';

@Component({
  selector: 'ama-profile-sheet-career',
  templateUrl: './profile-sheet-career.component.html',
  styleUrls: ['./profile-sheet-career.component.scss'],
})
export class ProfileSheetCareerComponent implements OnInit {
  @Input() careerEvents: CareerEvent[];

  constructor() {}

  ngOnInit(): void {}
}
