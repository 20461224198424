import { Routes } from '@angular/router';
import { BookPageComponent } from './book-page.component';
import { UserRouteAccessService } from '../../_shared/service/security/user-route-access.service';
import { Authority } from '../../_shared/service/security/authorities.enum';

export const bookPageRoutes: Routes = [
  {
    path: 'book',
    data: {
      authorities: [Authority.ROLE_USER],
    },
    canActivate: [UserRouteAccessService],
    children: [
      {
        path: '',
        component: BookPageComponent,
      },
    ],
  },
];
