import { createAction, props } from '@ngrx/store';

export const onHomeSelectedProfileId = createAction(
  '[HOME] on selected profile id',
  props<{ profileId: number }>()
);

export const onHomeSearchAllMagistrates = createAction(
  '[Home] on home search all magistrates'
);

export const onHomeSearchAllMagistratesInYear = createAction(
  '[Home] on home search all magistrates in year',
  props<{ inCarousel: boolean }>()
);
