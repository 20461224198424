import { Component, OnInit } from '@angular/core';
import { DestroyListener } from '../../../../_shared/service/widget/destroy-listener/destroy-listener';
import { select, Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { onClosedSearchSidebar } from '../_store/search.actions';
import { selectIsOpenSearchSidebar } from '../../../_store/navbar-page.selectors';
import { Observable } from 'rxjs';

@Component({
  selector: 'ama-search-sidebar',
  templateUrl: './search-sidebar.component.html',
  styleUrls: ['./search-sidebar.component.scss'],
})
export class SearchSidebarComponent extends DestroyListener implements OnInit {
  isOpen$: Observable<boolean>;

  constructor(private store: Store<any>) {
    super();
  }

  ngOnInit(): void {
    this.isOpen$ = this.store.pipe(
      select(selectIsOpenSearchSidebar),
      takeUntil(this.destroyed$)
    );
  }

  onCloseSidebar() {
    this.store.dispatch(onClosedSearchSidebar());
  }
}
