import { createAction, props } from '@ngrx/store';

export const restoreBookPageState = createAction(
  '[BOOK-PAGE] restore book page state'
);

export const scrollBookPageJobCode = createAction(
  '[Book-page Scroll] scroll book job code',
  props<{ code: string }>()
);

export const startLoading = createAction('[Book-page Loading] Start loading');

export const stopLoading = createAction('[Book-page Loading] Stop loading');
