import { Injectable } from '@angular/core';

@Injectable()
export class ScrollToService {
  constructor() {}

  private getOffset(selector: string): JQueryCoordinates {
    let target = $(selector);
    if (target) {
      return $(target).offset();
    }
  }

  scrollTo(target: any): void {
    if (target) {
      const firstEl = $(target).offset();
      if (firstEl) {
        $(target)
          .offsetParent()
          .animate({ scrollTop: firstEl.top, inline: 'start' }, 'slow', () => {
            target.focus();
          });
      }
    }
  }

  scrollBySelector(selector: string): void {
    let target = $(selector);
    this.scrollTo(target);
  }

  scrollToById(selector: any) {
    document
      .getElementById(selector)
      .scrollIntoView({ block: 'start', inline: 'start' });
  }

  scrollToTop(selector: string): void {
    if (this.getOffset(selector)) {
      let target = $(selector);
      $(target).scrollTop(0);
    }
  }
}
