<div class="container-fluid h-100">
  <div class="col-12 login-background">
    <div class="row justify-content-center pb-3">
      <div class="col-xl-4 col-sm-6">
        <div *ngIf="error === 'IDENTITY_ERROR'" class="row">
          <p-messages [hideTransitionOptions]="'1s'" [showTransitionOptions]="'1s'" severity="error">
            <ng-template pTemplate>
              <div class="col-12">
                <div class="row">
                  <div class="col-1">
                    <div class="row">
                      <div class="p-message-icon pi pi-info-circle"></div>
                    </div>
                  </div>
                  <div class="col-11">
                    <div class="row">
                      <strong>Erreur d'authentification</strong>
                      <span>Veuillez vérifier votre nom d'utilisateur et mot de passe.</span>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </p-messages>
        </div>
        <div *ngIf="error === 'UNAUTHORIZED_ERROR'" class="row">
          <p-messages [hideTransitionOptions]="'300ms'" [showTransitionOptions]="'300ms'" severity="error">
            <ng-template pTemplate>
              <div class="col-12">
                <div class="row">
                  <div class="col-1">
                    <div class="row">
                      <div class="p-message-icon pi pi-info-circle"></div>
                    </div>
                  </div>
                  <div class="col-11">
                    <div class="row">
                      <strong>Compte non autorisé</strong>
                      <span>Vous n'êtes pas autorisé à vous connecter à cette application.</span>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </p-messages>
        </div>
        <div *ngIf="hasLoggedOut$ | async" class="row justify-content-center">
          <p-messages [hideTransitionOptions]="'300ms'" [showTransitionOptions]="'300ms'" severity="success">
            <ng-template pTemplate>
              <div class="col-12">
                <div class="row">
                  <div class="col-12">
                    <div class="row">
                      <div class="p-message-icon pi pi-check"></div>
                      <div>Vous avez bien été déconnecté.</div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </p-messages>
        </div>
      </div>
    </div>
    <div class="row justify-content-center pt-5">
      <div class="col-xl-4 col-sm-6 my-auto">
        <div class="row">
          <div class="login align-middle">
            <div class="col-12">
              <div class="row justify-content-center">
                <div aria-label="login-logo" class="login-logo"></div>
              </div>
              <div class="row justify-content-center mb-3">
                <h4 class="ama-title text-center login-title">Connectez-vous pour accéder <br/> à l'annuaire des
                  magistrats</h4>
              </div>
              <div class="row justify-content-center">
                <div class="col-10">
                  <form (ngSubmit)="login()" [formGroup]="loginForm" role="form">
                    <div class="row">
                      <div class="form-group">
                        <label class="p-float-label" for="username">Identifiant</label>
                        <input
                          #username
                          aria-describedby="nom"
                          autocomplete="off"
                          class="ama-inputtext p-inputtext"
                          formControlName="username"
                          id="username"
                          name="username"
                          pInputText
                          placeholder="Identifiant"
                          type="text"
                        />
                      </div>
                    </div>
                    <div class="row">
                      <div class="form-group">
                        <label class="p-float-label" for="password">Mot de passe</label>
                        <input
                          #password
                          aria-describedby="password"
                          autocomplete="off"
                          class="ama-inputtext p-inputtext"
                          formControlName="password"
                          id="password"
                          name="password"
                          pInputText
                          placeholder="Mot de passe"
                          type="password"
                        />
                      </div>
                    </div>
                    <div class="row justify-content-center">
                      <button [disabled]="loginForm.invalid"
                              class="btn ama-button mt-4 login-button"
                              type="submit"
                      >
                        Se connecter
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
