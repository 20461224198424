import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Resource } from '../../../_shared/service/resource/resource';
import { CodeManagement } from './code-management';

@Injectable({
  providedIn: 'root',
})
export class CodeManagementService {
  private codeManagementResource: Resource<CodeManagement>;

  constructor(private httpClient: HttpClient) {
    this.codeManagementResource = new Resource<CodeManagement>(
      httpClient,
      'code-management'
    );
  }

  findOne$(): Observable<CodeManagement> {
    return this.codeManagementResource.findOne(null);
  }
}
