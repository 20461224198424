import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { initialAMA } from './_store/ama.actions';

@Component({
  selector: 'ama-app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'ama';
  constructor(private store: Store<any>) {}

  ngOnInit(): void {
    this.store.dispatch(initialAMA());
  }
}
