import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { LoginService } from '../../core/login/login.service';
import { StateStorageService } from '../security/state-storage.service';

const LOGIN_URL = 'login';

@Injectable()
export class AuthExpiredInterceptor implements HttpInterceptor {
  constructor(
    private loginService: LoginService,
    private stateStorageService: StateStorageService,
    private router: Router
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap(null, (err: HttpErrorResponse) => {
        if (
          err.status === 401 &&
          err.url &&
          !err.url.includes('api/account') &&
          !err.url.includes('api/authenticate')
        ) {
          this.stateStorageService.storeUrl(
            this.router.routerState.snapshot.url
          );
          this.loginService.logout();
          this.router.navigate([LOGIN_URL]);
        }
      })
    );
  }
}
