import { createAction, props } from '@ngrx/store';
import { Organization } from '../../_shared/organization/organization';

export const selectOrganization = createAction(
  '[Navbar ITEM] Select Organization',
  props<{ organization: Organization }>()
);

export const selectExternalJudges = createAction(
  '[Navbar ITEM] select external judges'
);

export const startNavbarLogout = createAction(
  '[NAVBAR LOGOUT] Start navbar logout'
);
