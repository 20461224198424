import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { DestroyListener } from '../_shared/service/widget/destroy-listener/destroy-listener';
import { referentialsFetchRequested } from './_store/navbar-page.action';
import { takeUntil } from 'rxjs/operators';
import { NO_TOP_BAR_URLS, SCROLL_Y_URLS } from '../app.constants';
import { selectCurrentUrl } from '../_store/ama.selectors';

@Component({
  selector: 'ama-navbar-page',
  templateUrl: './navbar-page.component.html',
  styleUrls: ['./navbar-page.component.scss'],
})
export class NavbarPageComponent extends DestroyListener implements OnInit {
  url: string;
  scrollPages = SCROLL_Y_URLS;
  noTopBarPages = NO_TOP_BAR_URLS;
  constructor(private store: Store<any>) {
    super();
  }

  ngOnInit(): void {
    this.store
      .pipe(select(selectCurrentUrl), takeUntil(this.destroyed$))
      .subscribe((url) => (this.url = url));
    this.store.dispatch(referentialsFetchRequested());
  }
}
