import { Injectable } from '@angular/core';
import { Resource } from '../../../_shared/service/resource/resource';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DetachmentOrganization } from './detachment-organization';

@Injectable({
  providedIn: 'root',
})
export class DetachmentOrganizationService {
  private detachmentOrganizationResource: Resource<DetachmentOrganization>;

  constructor(private httpClient: HttpClient) {
    this.detachmentOrganizationResource = new Resource<DetachmentOrganization>(
      httpClient,
      'detachment-organizations'
    );
  }

  getAll$(): Observable<DetachmentOrganization[]> {
    return this.detachmentOrganizationResource.getAll();
  }
}
