import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProfileSheetCareerComponent } from './profile-sheet-career.component';
import { TimelineModule } from 'primeng/timeline';
import { SharedModule } from 'primeng/api';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [ProfileSheetCareerComponent],
  imports: [CommonModule, TimelineModule, SharedModule, MatIconModule],
  exports: [ProfileSheetCareerComponent],
})
export class ProfileSheetCareerModule {}
