import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { DestroyListener } from '../../_shared/service/widget/destroy-listener/destroy-listener';
import { selectProfileSheet } from '../_store/navbar-page.selectors';
import { takeUntil } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ProfileSheet } from '../_shared/profile-sheet/profile-sheet';
import { onDestroySheetPage } from './_store/profile-sheet-page.actions';

@Component({
  selector: 'ama-profile-sheet-page',
  templateUrl: './profile-sheet-page.component.html',
  styleUrls: ['./profile-sheet-page.component.scss'],
})
export class ProfileSheetPageComponent extends DestroyListener
  implements OnInit {
  profileSheet$: Observable<ProfileSheet>;
  constructor(private store: Store<any>) {
    super();
  }

  ngOnInit(): void {
    this.profileSheet$ = this.store.pipe(
      select(selectProfileSheet),
      takeUntil(this.destroyed$)
    );
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.store.dispatch(onDestroySheetPage());
  }
}
