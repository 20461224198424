import { Organization } from '../_shared/organization/organization';
import { Profile } from '../_shared/profile/profile';
import { CodeManagement } from '../_shared/code-management/code-management';
import { Job } from '../_shared/job/job';
import { SearchRequest } from '../_shared/search/search-request';
import { AppointmentMode } from '../_shared/appointment-mode/appointment-mode';
import { DetachmentOrganization } from '../_shared/detachment-organization/detachment-organization';

export interface Referential {
  profiles: Profile[];
  organizations: Organization[];
  jobs: Job[];
  codeManagement: CodeManagement;
  appointmentModes: AppointmentMode[];
  detachmentOrganizations: DetachmentOrganization[];
}

export interface NavbarPageStates {
  referential: Referential;
  selectedProfileId: number;
  isVisibleProfileSheetDialog: boolean;
  selectedOrganization: Organization;
  openSearchSidebar: boolean;
  searchRequest: SearchRequest;
  isLoading: boolean;
}

// Initial
export const initialReferential: Referential = {
  profiles: [],
  organizations: [],
  jobs: [],
  codeManagement: null,
  appointmentModes: [],
  detachmentOrganizations: [],
};
export const initialNavbarPageStates: NavbarPageStates = {
  referential: initialReferential,
  selectedProfileId: null,
  isVisibleProfileSheetDialog: false,
  selectedOrganization: null,
  openSearchSidebar: false,
  searchRequest: null,
  isLoading: false,
};
