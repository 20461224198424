export const CAREER_EVENT = {
  EMPTY: 'Non renseigné',
  IN_PROGRESS_DATE: 'Depuis {{startDate}}',
  TO_COME_UP_DATE: 'A partir de {{startDate}}',
  BETWEEN_DATE: '{{startDate}} - {{endDate}}',
  SOLO_DATE: '{{date}}',
  ICON: {
    GRADE: 'arrow_grade',
    POST: 'suit_case_assignment',
    DETACHMENT: 'detachment',
  },
  MAX_DATE: '2999',
  DETACHMENT_UO_CODE: '013REPO006',
  TYPE: {
    JOB: 0,
    GRADE: 1,
    DETACHMENT: 2,
  },
  ORGANIZATION_PARTICULAR_CASE: {
    '013E200025': "UO générique Conseil d'Etat",
  },
  MAGISTRATE_INTERN_JOB_CODE: 'MAGIS STAG',
};
