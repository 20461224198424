import { Component, OnInit } from '@angular/core';
import { backToHomeAction } from '../../../../_store/ama.actions';
import { Store } from '@ngrx/store';

@Component({
  selector: 'ama-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss'],
})
export class LogoComponent implements OnInit {
  constructor(private store: Store<any>) {}

  ngOnInit(): void {}

  onLogoClicked() {
    this.store.dispatch(backToHomeAction());
  }
}
