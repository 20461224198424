import { AfterViewInit, Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { DestroyListener } from '../../../_shared/service/widget/destroy-listener/destroy-listener';
import { takeUntil } from 'rxjs/operators';
import { ProfileJob } from '../_shared/profile-job/profile-job';
import { Observable } from 'rxjs';
import { scrollBookPageJobCode } from '../_store/book-page.action';
import { onProfileJobSelectCardProfileId } from './_store/profile-jobs.actions';
import { selectSelectedProfileJobs } from '../_store/book-page.selectors';
import { ProfileCard } from '../_shared/profile-card/profile-card';
import { AVATAR_DEFAULT_NAME } from '../../_shared/profile/profile.strings';
import { scrollToProfileId } from '../../_store/navbar-page.action';

@Component({
  selector: 'ama-profile-jobs',
  templateUrl: './profile-jobs.component.html',
  styleUrls: ['./profile-jobs.component.scss'],
})
export class ProfileJobsComponent extends DestroyListener
  implements OnInit, AfterViewInit {
  profileJobs$: Observable<ProfileJob[]>;
  avatarDefaultName = AVATAR_DEFAULT_NAME;

  constructor(private store: Store<any>) {
    super();
  }

  trackByJob = (index, job: ProfileJob) => job.code;
  trackByCard = (index, card: ProfileCard) => card.metadata.profileId;

  ngOnInit(): void {
    this.profileJobs$ = this.store.pipe(
      select(selectSelectedProfileJobs),
      takeUntil(this.destroyed$)
    );
  }

  onJobChange(code: string) {
    this.store.dispatch(scrollBookPageJobCode({ code }));
  }

  onProfileSelected(profileId: number) {
    this.store.dispatch(onProfileJobSelectCardProfileId({ profileId }));
  }

  ngAfterViewInit(): void {
    this.store.dispatch(scrollToProfileId());
  }
}
