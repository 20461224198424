import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, tap } from 'rxjs/operators';
import { EnvInfo } from './env-info';
import { Observable, of } from 'rxjs';
import { Resource } from '../../service/resource/resource';

@Injectable({
  providedIn: 'root',
})
export class EnvInfoService {
  private envInfoResource: Resource<EnvInfo>;
  private envInfo = new EnvInfo();
  private resolved = false;

  constructor(private httpClient: HttpClient) {
    this.envInfoResource = new Resource<EnvInfo>(httpClient, 'env-info');
  }

  getProfileInfo(): Observable<EnvInfo> {
    if (this.resolved) {
      return of(this.envInfo);
    } else {
      return this.envInfoResource.findOne(null).pipe(
        map((res) => {
          this.envInfo.activeProfiles = res.activeProfiles;
          return this.envInfo;
        }),
        tap((envInfo) => (this.envInfo = envInfo)),
        tap(() => (this.resolved = true))
      );
    }
  }
  getEnvInfoDirect(): EnvInfo {
    return this.envInfo;
  }
}
