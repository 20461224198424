import { CareerEvent } from './career-event';
import { CareerGrade, CareerJob } from '../../profile/profile';
import { CodeManagement } from '../../code-management/code-management';
import { Job } from '../../job/job';
import { getGrade } from '../../code-management/code-management.functions';
import { LocalDate } from '../../../../_shared/domain/local-date';
import { CAREER_EVENT } from './career-event.strings';
import { Organization } from '../../organization/organization';
import { getFirstAttachmentOrganization } from '../../organization/organization.functions';
import { getJob, getJobWithoutHF } from '../../job/job.functions';
import { ORGANIZATION_LONG_LABEL } from '../../organization/organization.strings';

export function getCareerEvents(
  profileCareerGrades: CareerGrade[],
  profileCareerJobs: CareerJob[],
  jobs: Job[],
  codeManagement: CodeManagement,
  organizationChildrenCodes: string[][],
  parentOrganizations: Organization[],
  profileJobCode: string,
  detachmentOrganizationCodes: string[]
): CareerEvent[] {
  const careerGradeEvents = getCareerGradeEvents(
    profileCareerGrades,
    codeManagement
  );
  const careerJobEvents = getCareerJobEvents(
    profileCareerJobs,
    jobs,
    organizationChildrenCodes,
    parentOrganizations,
    profileJobCode,
    detachmentOrganizationCodes
  );
  const careerDetachmentEvents = getCareerDetachmentEvents(
    profileCareerJobs,
    jobs
  );
  return [...careerGradeEvents, ...careerJobEvents, ...careerDetachmentEvents]
    .sort((a, b) => a.metadata.startDateTime - b.metadata.startDateTime)
    .reverse();
}

function getCareerJobEvents(
  profileCareerJobs: CareerJob[],
  jobs: Job[],
  organizationChildrenCodes: string[][],
  parentOrganizations: Organization[],
  profileJobCode: string,
  detachmentOrganizationCodes: string[]
): CareerEvent[] {
  return profileCareerJobs
    .filter(
      (careerJob) =>
        careerJob.organizationCode !== CAREER_EVENT.DETACHMENT_UO_CODE
    )
    .reduce((careerEvents: CareerEvent[], careerJob: CareerJob) => {
      const attachmentOrganization = getFirstAttachmentOrganization(
        organizationChildrenCodes,
        parentOrganizations,
        careerJob.organizationCode
      );
      const currentOrganizationLabel = getCareerJobOrganizationStr(
        attachmentOrganization,
        careerJob,
        profileJobCode
      );
      careerEvents.push({
        dates: getCareerJobDatesStr(careerJob),
        assignment: getCareerJobAssignmentStr(careerJob, jobs),
        organization: currentOrganizationLabel,
        type: detachmentOrganizationCodes.includes(careerJob.organizationCode)
          ? CAREER_EVENT.TYPE.DETACHMENT
          : CAREER_EVENT.TYPE.JOB,
        icon: detachmentOrganizationCodes.includes(careerJob.organizationCode)
          ? CAREER_EVENT.ICON.DETACHMENT
          : CAREER_EVENT.ICON.POST,
        metadata: {
          startDateTime: getMetadataStartDateTime(careerJob.startDate),
        },
      });
      return careerEvents;
    }, []);
}

function getCareerJobOrganizationStr(
  attachmentOrganization: Organization,
  careerJob: CareerJob,
  profileJobCode: string
) {
  if (attachmentOrganization) {
    if (
      CAREER_EVENT.ORGANIZATION_PARTICULAR_CASE[attachmentOrganization.code] &&
      profileJobCode === CAREER_EVENT.MAGISTRATE_INTERN_JOB_CODE
    ) {
      return getEventOrganizationLongLabel(attachmentOrganization.longLabel);
    } else {
      if (
        CAREER_EVENT.ORGANIZATION_PARTICULAR_CASE[attachmentOrganization.code]
      ) {
        return careerJob.organizationLongLabel;
      }
      return attachmentOrganization.longLabel;
    }
  } else {
    return careerJob.organizationLongLabel;
  }
}

function getCareerGradeEvents(
  profileCareerGrades: CareerGrade[],
  codeManagement: CodeManagement
): CareerEvent[] {
  return profileCareerGrades.reduce(
    (careerEvents: CareerEvent[], careerGrade: CareerGrade) => {
      careerEvents.push({
        dates: CAREER_EVENT.SOLO_DATE.replace(
          '{{date}}',
          new LocalDate(careerGrade.nominationDate).getYearStr()
        ),
        assignment:
          getGrade(careerGrade.code, codeManagement.grades)?.shortLabel ??
          codeManagement.careerGrades.find((g) => g.code === careerGrade.code)
            ?.longLabel ??
          CAREER_EVENT.EMPTY,
        type: CAREER_EVENT.TYPE.GRADE,
        icon: CAREER_EVENT.ICON.GRADE,
        metadata: {
          startDateTime: getMetadataStartDateTime(careerGrade.nominationDate),
        },
      });
      return careerEvents;
    },
    []
  );
}

function getCareerDetachmentEvents(
  profileCareerJobs: CareerJob[],
  jobs: Job[]
): CareerEvent[] {
  return profileCareerJobs
    .filter(
      (careerJob) =>
        careerJob.organizationCode === CAREER_EVENT.DETACHMENT_UO_CODE
    )
    .reduce((careerEvents: CareerEvent[], careerJob: CareerJob) => {
      careerEvents.push({
        dates: getCareerJobDatesStr(careerJob),
        assignment: getCareerJobAssignmentStr(careerJob, jobs),
        organization: careerJob.organizationLongLabel,
        type: CAREER_EVENT.TYPE.DETACHMENT,
        icon: CAREER_EVENT.ICON.DETACHMENT,
        metadata: {
          startDateTime: getMetadataStartDateTime(careerJob.startDate),
        },
      });
      return careerEvents;
    }, []);
}

function getCareerJobDatesStr(careerJob: CareerJob): string {
  if (new LocalDate().isFuture(careerJob.startDate)) {
    return CAREER_EVENT.TO_COME_UP_DATE.replace(
      '{{startDate}}',
      new LocalDate(careerJob.startDate).getMonthYearStr()
    );
  }
  if (
    new LocalDate().isTodayBetweenDates(careerJob.startDate, careerJob.endDate)
  ) {
    return CAREER_EVENT.IN_PROGRESS_DATE.replace(
      '{{startDate}}',
      new LocalDate(careerJob.startDate).getYearStr()
    );
  }
  return CAREER_EVENT.BETWEEN_DATE.replace(
    '{{startDate}}',
    new LocalDate(careerJob.startDate).getYearStr()
  ).replace('{{endDate}}', new LocalDate(careerJob.endDate).getYearStr());
}

function getCareerJobAssignmentStr(careerJob: CareerJob, jobs: Job[]): string {
  return (
    getJobWithoutHF(careerJob.jobLongLabel) ??
    getJobWithoutHF(getJob(careerJob.jobCode, jobs)?.longLabel) ??
    CAREER_EVENT.EMPTY
  );
}

function getMetadataStartDateTime(date: string): number {
  return new LocalDate(date).getTime();
}

function getEventOrganizationLongLabel(organizationStr: string): string {
  return ORGANIZATION_LONG_LABEL[organizationStr] ?? organizationStr;
}
