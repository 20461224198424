import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OpenSearchButtonComponent } from './open-search-button/open-search-button.component';
import { SearchComponent } from './search.component';
import { SearchSidebarComponent } from './sidebar/search-sidebar.component';
import { SearchStoreModule } from './_store/module';
import { FormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { SidebarModule } from 'primeng/sidebar';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  declarations: [
    OpenSearchButtonComponent,
    SearchComponent,
    SearchSidebarComponent,
  ],
  imports: [
    CommonModule,
    SearchStoreModule,
    FormsModule,
    DropdownModule,
    SidebarModule,
    MatIconModule,
    MatButtonModule,
  ],
  exports: [SearchComponent, SearchSidebarComponent, OpenSearchButtonComponent],
})
export class SearchModule {}
