import { createSelector } from '@ngrx/store';
import {
  selectAllProfiles,
  selectGrades,
  selectSelectedOrganization,
} from '../../_store/navbar-page.selectors';
import { getAllProfileJobs } from '../_shared/profile-job/profile-job.functions';
import { getOrganizationChildrenCodes } from '../../_shared/organization/organization.functions';
import { ORGANIZATION_LONG_LABEL } from '../../_shared/organization/organization.strings';

// ORGANIZATION
export const selectSelectedOrganizationLabel = createSelector(
  selectSelectedOrganization,
  (organization) =>
    ORGANIZATION_LONG_LABEL[organization?.longLabel] ??
    organization?.longLabel ??
    null
);

const selectSelectedOrganizationCodes = createSelector(
  selectSelectedOrganization,
  getOrganizationChildrenCodes
);

const selectSelectedProfiles = createSelector(
  selectAllProfiles,
  selectSelectedOrganizationCodes,
  (profiles, organizationCodes) =>
    profiles.filter((p) => organizationCodes.includes(p.post.organization.code))
);

export const selectSelectedProfileJobs = createSelector(
  selectSelectedProfiles,
  selectGrades,
  getAllProfileJobs
);
