import {
  AVATAR_COUNT_MAX,
  AVATAR_DEFAULT_NAME,
  AVATAR_DEFAULT_NAME_SVG,
} from './profile.strings';

export function getRandomDefaultPicture(pictureName: string): string {
  return pictureName.includes(AVATAR_DEFAULT_NAME)
    ? AVATAR_DEFAULT_NAME_SVG.replace(
        '{{randomNumber}}',
        (Math.floor(Math.random() * AVATAR_COUNT_MAX) + 1).toString()
      )
    : pictureName;
}
