import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Organization } from '../_shared/organization/organization';
import { takeUntil } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { DestroyListener } from '../../_shared/service/widget/destroy-listener/destroy-listener';
import {
  selectExternalJudges,
  selectOrganization,
  startNavbarLogout,
} from './_store/navbar.action';
import {
  selectNavbarPageIsLoading,
  selectSelectedOrganization,
} from '../_store/navbar-page.selectors';
import { NavbarPageStates } from '../_store/navbar-page.states';
import { NavbarPanel } from './navbar-panel';
import {
  selectNavbarPanelGroups,
  selectNavbarPanelSingles,
} from './_store/navbar.selectors';
import {
  EXTERNAL_JUDGES_EXCEL_SHEET_LINK,
  EXTERNAL_JUDGES_LINK_TOOLTIP_MESSAGE,
} from './navbar.strings';

@Component({
  selector: 'ama-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent extends DestroyListener implements OnInit {
  navbarPanelGroups$: Observable<NavbarPanel[]>;
  navbarPanelSingles$: Observable<NavbarPanel[]>;
  isLoading: boolean;
  selectedOrganization: Organization;
  externalJudgesExcelSheetLink: string = EXTERNAL_JUDGES_EXCEL_SHEET_LINK;
  externalJudgesLinkTooltipMessage: string = EXTERNAL_JUDGES_LINK_TOOLTIP_MESSAGE;

  constructor(private store: Store<NavbarPageStates>) {
    super();
  }

  ngOnInit(): void {
    this.store
      .pipe(select(selectSelectedOrganization), takeUntil(this.destroyed$))
      .subscribe(
        (selectedOrganization) =>
          (this.selectedOrganization = selectedOrganization)
      );
    this.navbarPanelGroups$ = this.store.pipe(
      select(selectNavbarPanelGroups),
      takeUntil(this.destroyed$)
    );
    this.navbarPanelSingles$ = this.store.pipe(
      select(selectNavbarPanelSingles),
      takeUntil(this.destroyed$)
    );
    this.store
      .pipe(select(selectNavbarPageIsLoading, takeUntil(this.destroyed$)))
      .subscribe((isLoading) => (this.isLoading = isLoading));
  }

  onOrganizationClicked(organization: Organization) {
    this.store.dispatch(selectOrganization({ organization }));
  }

  onLogout() {
    this.store.dispatch(startNavbarLogout());
  }

  onExternalJudges() {
    this.store.dispatch(selectExternalJudges());
  }
}
