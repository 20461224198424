<div
  (sectionChange)="onJobChange($event)"
  [spiedTags]="['DIV']"
  class="profile-jobs"
  id="profile-jobs"
  scrollSpy
>
  <div
    *ngFor="let job of profileJobs$ | async; trackBy: trackByJob"
    [attr.id]="job.code"
    class="jobs"
  >
    <div class="jobs title">
      <span>{{ job.label }}</span>
    </div>
    <div class="jobs content">
      <p-card
        *ngFor="let card of job.cards; trackBy: trackByCard"
        [header]="card.header"
        [subheader]="card.subHeader"
        [style]="card.style"
        [attr.id]="card.metadata.profileId"
        (click)="onProfileSelected(card.metadata.profileId)"
      >
        <p-header>
          <div
            class="container-fluid h-100"
            [ngClass]="{
              'background-gray': card.picturePath.includes(avatarDefaultName)
            }"
          >
            <div class="text-center">
              <img
                [src]="card.picturePath"
                style="min-height: 150px; width: 100px;"
                alt="avatar"
              />
            </div>
          </div>
        </p-header>
        {{ card.businessPhone }}
        <p-footer>
          <a href="mailto:{{ card.email }}">{{ card.email }}</a>
        </p-footer>
      </p-card>
    </div>
  </div>
</div>
