import { Route } from '@angular/router';
import { UserRouteAccessService } from '../_shared/service/security/user-route-access.service';
import { LoginComponent } from './login.component';

export const loginRoute: Route = {
  path: 'login',
  component: LoginComponent,
  data: {
    authorities: [],
  },
  canActivate: [UserRouteAccessService],
};
