export const SEARCH_RESULTS = {
  RESULT_COUNT: '{{count}} résultat{{s}}',
  SEARCH_TITLE: 'Résultats de recherche pour ',
  TITLE_ITEMS: '"{{items}}"',
  COMMA: ', ',
  TITLE_ALL_MAGISTRATE: 'Magistrats de tous les ressorts',
  TITLE_APPOINTED_THIS_YEAR: 'Les magistrats nommés en {{year}}',
  SEPARATOR: {
    SEMICOLON: ';',
    SPACE: ' ',
  },
};
