import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { routerReducer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { EffectsModule } from '@ngrx/effects';
import { AmaEffects } from './ama.effects';
import { environment } from '../../environments/environment';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { AmaReducer } from './ama.reducer';

@NgModule({
  imports: [
    StoreModule.forRoot({
      router: routerReducer,
    }),
    EffectsModule.forRoot([AmaEffects]),
    StoreModule.forFeature('AMA', AmaReducer),
    StoreRouterConnectingModule.forRoot(),
    environment.production
      ? []
      : StoreDevtoolsModule.instrument({
          maxAge: 25, // Retains last 25 states
        }),
  ],
})
export class AmaStoreModule {}
