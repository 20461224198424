import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { NavbarPageReducer } from './navbar-page.reducer';
import { EffectsModule } from '@ngrx/effects';
import { NavbarPageEffects } from './navbar-page.effects';

@NgModule({
  imports: [
    EffectsModule.forFeature([NavbarPageEffects]),
    StoreModule.forFeature('NAVBAR-PAGE', NavbarPageReducer),
  ],
})
export class NavbarPageStoreModule {}
