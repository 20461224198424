import { createFeatureSelector, createSelector } from '@ngrx/store';
import { NavbarPageStates } from './navbar-page.states';
import { getOrganizationChildrenCodes } from '../_shared/organization/organization.functions';
import { getProfileSheet } from '../_shared/profile-sheet/profile-sheet.functions';

const selectFeature = createFeatureSelector<NavbarPageStates>('NAVBAR-PAGE');

export const selectReferentials = createSelector(
  selectFeature,
  (state: NavbarPageStates) => state.referential
);

export const selectCodeManagement = createSelector(
  selectReferentials,
  (referentials) => referentials?.codeManagement ?? null
);

export const selectGrades = createSelector(
  selectCodeManagement,
  (codeManagement) => codeManagement?.grades ?? null
);

const selectAllDetachmentOrganizations = createSelector(
  selectReferentials,
  (referentials) => referentials?.detachmentOrganizations ?? null
);

export const selectAllDetachmentOrganizationCodes = createSelector(
  selectAllDetachmentOrganizations,
  (detachmentOrganizations) =>
    detachmentOrganizations
      ? detachmentOrganizations.map((org) => org.code)
      : null
);

export const selectJobs = createSelector(
  selectReferentials,
  (referentials) => referentials.jobs
);

// PROFILE
export const selectAllProfiles = createSelector(
  selectReferentials,
  (referentials) => referentials.profiles
);

export const selectTotalProfiles = createSelector(
  selectAllProfiles,
  (profiles) => profiles.length.toLocaleString()
);

export const selectSelectedProfileId = createSelector(
  selectFeature,
  (state) => state.selectedProfileId
);

export const selectSelectedProfile = createSelector(
  selectAllProfiles,
  selectSelectedProfileId,
  (profiles, selectedProfileId) =>
    profiles.find((p) => p.id === selectedProfileId)
);

export const selectIsVisibleProfileSheet = createSelector(
  selectFeature,
  (state) => state.isVisibleProfileSheetDialog && !!state.selectedProfileId
);

// ORGANIZATION
export const selectAllOrganizations = createSelector(
  selectReferentials,
  (referentials) => referentials.organizations
);

export const selectAllOrganizationChildrenCodes = createSelector(
  selectAllOrganizations,
  (allOrganizations) =>
    allOrganizations.map((organization) =>
      getOrganizationChildrenCodes(organization)
    )
);

export const selectAllParentOrganizations = createSelector(
  selectAllOrganizations,
  (allOrganizations) => allOrganizations.filter((o) => o.parent == null)
);

export const selectSelectedOrganization = createSelector(
  selectFeature,
  (state) => state.selectedOrganization
);

// APPOINTMENT MODES
export const selectAllAppointmentModes = createSelector(
  selectReferentials,
  (referentials) => referentials.appointmentModes
);

// SEARCH REQUEST
export const selectIsOpenSearchSidebar = createSelector(
  selectFeature,
  (state) => state.openSearchSidebar
);

export const selectSearchRequest = createSelector(
  selectFeature,
  (state) => state.searchRequest
);

export const selectNavbarPageIsLoading = createSelector(
  selectFeature,
  (state) => state.isLoading
);

export const selectProfileSheet = createSelector(
  selectSelectedProfile,
  selectAllOrganizationChildrenCodes,
  selectAllParentOrganizations,
  selectCodeManagement,
  selectJobs,
  selectAllAppointmentModes,
  selectAllDetachmentOrganizationCodes,
  getProfileSheet
);
