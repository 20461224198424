import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavbarPageComponent } from './navbar-page.component';
import { NavbarComponent } from './navbar/navbar.component';
import { RouterModule } from '@angular/router';
import { BookPageModule } from './book-page/book-page.module';
import { AmaWidgetModule } from '../_shared/service/widget/ama-widget.module';
import { NavbarStoreModule } from './navbar/_store/module';
import { NavbarPageStoreModule } from './_store/module';
import { MatIconModule } from '@angular/material/icon';
import { DropdownModule } from 'primeng/dropdown';
import { HomePageModule } from './home-page/home-page.module';
import { SharedModule } from './_shared/shared.module';
import { ProfileSheetDialogModule } from './profile-sheet-dialog/profile-sheet-dialog.module';
import { TooltipModule } from 'primeng/tooltip';
import { AccordionModule } from 'primeng/accordion';
import { ProfileSheetPageModule } from './profile-sheet-page/profile-sheet-page.module';
import { SearchModule } from './_shared/search/search.module';
import { TopBarComponent } from './topbar/top-bar.component';

@NgModule({
  declarations: [NavbarPageComponent, NavbarComponent, TopBarComponent],
  exports: [NavbarPageComponent],
  imports: [
    CommonModule,
    RouterModule,
    BookPageModule,
    AmaWidgetModule,
    NavbarPageStoreModule,
    NavbarStoreModule,
    MatIconModule,
    DropdownModule,
    HomePageModule,
    SharedModule,
    ProfileSheetDialogModule,
    TooltipModule,
    AccordionModule,
    ProfileSheetPageModule,
    SearchModule,
  ],
})
export class NavbarPageModule {}
