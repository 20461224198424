import { createAction, props } from '@ngrx/store';
import { BodyAppointmentMode } from '../body-appointment-mode/body-appointment-mode';

export const restoreSearchResultPageState = createAction(
  '[SEARCH RESULTS] restore search result page state'
);

export const onEditSearchSidebar = createAction(
  '[SEARCH RESULTS] on edit search sidebar'
);
export const onSearchSelectedProfileId = createAction(
  '[PROFILE ROW] on selected profile id',
  props<{ profileId: number }>()
);

export const onFilterProfileRowsByDateYears = createAction(
  '[SLIDER DATE] on filter profile rows by date years',
  props<{ minDate: number; maxDate: number }>()
);

export const updateFilterDateYears = createAction(
  '[SLIDER DATE] update filter date year',
  props<{ minDate: number; maxDate: number }>()
);

export const onFilterProfileRowByAppointmentModes = createAction(
  '[BODY APPOINTMENT MODES CHECK BOXES] on filter profile rows by appointment modes',
  props<{ selectedAppointmentModes: BodyAppointmentMode[] }>()
);

export const updateFilterBodyAppointmentModes = createAction(
  '[BODY APPOINTMENT MODES CHECK BOXES] update filter appointment modes',
  props<{ selectedAppointmentModes: BodyAppointmentMode[] }>()
);

export const resetSearchResultPageState = createAction(
  '[SEARCH RESULT PAGE] resetSearchResultPageState'
);

export const scrollToRowIndex = createAction(
  '[PROFILE ROW] scroll to row index'
);

export const setScrollIndex = createAction(
  '[PROFILE ROW] set scroll index',
  props<{ index: number }>()
);
