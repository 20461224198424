<div class="ama-navbar flex-column space-between h-100 w-100">
  <div class="container h-100">
    <div class="row">
      <div class="logo my-auto">
        <ama-logo></ama-logo>
      </div>
    </div>
    <div class="row pb-2">
      <span class="nav-title jurisdiction">JURIDICTIONS</span>
    </div>
    <div class="row">
      <div *ngIf="isLoading" class="ssc-wrapper w-100">
        <div class="ssc-head-line mb-3"></div>
        <div class="ssc-head-line mb-3"></div>
        <div class="ssc-head-line w-50 mb-3"></div>
        <div class="ssc-head-line w-50"></div>
      </div>
      <div *ngIf="!isLoading" class="w-100">
        <p-accordion>
          <p-accordionTab
            *ngFor="let panel of navbarPanelGroups$ | async"
            [header]="panel.title"
          >
            <ng-template pTemplate="content">
              <ul class="nav flex-column panel-list w-100">
                <li
                  (click)="onOrganizationClicked(organisation)"
                  *ngFor="let organisation of panel.organizations"
                  [ngClass]="{
                    active:
                      selectedOrganization &&
                      selectedOrganization.code === organisation.code
                  }"
                  class="nav-item"
                >
                  <span class="panel-item-span">{{
                    organisation.shortLabel
                  }}</span>
                </li>
              </ul>
            </ng-template>
          </p-accordionTab>
        </p-accordion>
        <ul class="nav flex-column list">
          <li
            (click)="onOrganizationClicked(panel.organizations[0])"
            *ngFor="let panel of navbarPanelSingles$ | async"
            [ngClass]="{
              active:
                selectedOrganization &&
                selectedOrganization.code === panel.organizations[0].code
            }"
            class="nav-item"
          >
            <span>{{ panel.title }}</span>
          </li>
        </ul>
      </div>
    </div>
    <div class="row pt-3">
      <span (click)="onExternalJudges()" class="nav-title">
        <a
          pTooltip="{{ externalJudgesLinkTooltipMessage }}"
          tooltipPosition="bottom"
          tooltipEvent="hover"
          href="{{ externalJudgesExcelSheetLink }}"
          target="_blank"
          >Détachements
        </a>
      </span>
    </div>
  </div>
  <div (click)="onLogout()" class="logout-btn-container">
    <span class="logout-btn">
      <mat-icon svgIcon="logout"> </mat-icon>
      <span class="button-title">Déconnexion</span>
    </span>
  </div>
</div>
