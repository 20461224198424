import { Component, OnInit } from '@angular/core';
import { MatomoService } from '../_shared/service/matomo/matomo.service';
import { COOKIE_FALSE, COOKIE_MESSAGE, COOKIE_TRUE } from './cookie.strings';
import { CookieService } from 'ngx-cookie-service';
import { EnvInfoService } from '../_shared/domain/env-info/env-info.service';
import { LocalDate } from '../_shared/domain/local-date';
import { select, Store } from '@ngrx/store';
import { selectEnvironment } from '../_store/ama.selectors';

@Component({
  selector: 'ama-cookie',
  templateUrl: './cookie.component.html',
  styleUrls: ['./cookie.component.scss'],
})
export class CookieComponent implements OnInit {
  cookieMessage = COOKIE_MESSAGE;
  isChoice = false;
  env: string;
  constructor(
    private matomoService: MatomoService,
    private cookieService: CookieService,
    private envInfoService: EnvInfoService,
    private store: Store<any>
  ) {}

  ngOnInit(): void {
    this.store.pipe(select(selectEnvironment)).subscribe((envInfo) => {
      this.env = envInfo;
      const cookie = this.cookieService.get('matomo-analytics');
      if (cookie) {
        this.isChoice = true;
        if (cookie === COOKIE_TRUE) {
          this.matomoService.init(this.env);
        }
      }
    });
  }

  onChoice(choice: boolean) {
    this.cookieService.set(
      'matomo-analytics',
      choice ? COOKIE_TRUE : COOKIE_FALSE,
      new LocalDate().addYears(1),
      this.env
    );
    if (choice) {
      this.matomoService.init(this.env);
    }
    this.isChoice = true;
  }
}
