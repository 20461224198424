import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { logout } from '../../../_store/ama.actions';
import { map } from 'rxjs/operators';
import { restoreBookPageState } from './book-page.action';

@Injectable()
export class BookPageEffects {
  @Effect() restoreNavbarPageState = this.actions$.pipe(
    ofType(logout),
    map(() => restoreBookPageState())
  );
  constructor(private actions$: Actions) {}
}
