import {
  AfterContentInit,
  AfterViewInit,
  Component,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ProfileRow } from '../_shared/profile-row/profile-row';
import { DestroyListener } from '../../_shared/service/widget/destroy-listener/destroy-listener';
import { select, Store } from '@ngrx/store';
import {
  selectCurrentMaxBodyDateYear,
  selectCurrentMinBodyDateYear,
  selectCurrentRangeBodyDateYears,
  selectMaxBodyDateYear,
  selectMinBodyDateYear,
  selectScrollIndex,
  selectSearchBodyAppointmentModes,
  selectSearchRequestedProfileRows,
  selectSearchTitle,
  selectSearchTitleCount,
} from './_store/search-results-page.selectors';
import { debounceTime, takeUntil } from 'rxjs/operators';
import {
  onEditSearchSidebar,
  onFilterProfileRowByAppointmentModes,
  onFilterProfileRowsByDateYears,
  onSearchSelectedProfileId,
  resetSearchResultPageState,
  scrollToRowIndex,
} from './_store/search-results-page.actions';
import { UI_DEBOUNCE_MS } from '../../app.constants';
import { BodyAppointmentMode } from './body-appointment-mode/body-appointment-mode';
import { VirtualScroller } from 'primeng/virtualscroller';

const SEE_MORE = 'Voir plus';
const SEE_LESS = 'Voir moins';

@Component({
  selector: 'ama-sought-profile',
  templateUrl: './search-results-page.component.html',
  styleUrls: ['./search-results-page.component.scss'],
})
export class SearchResultsPageComponent extends DestroyListener
  implements OnInit, AfterViewInit {
  @ViewChild('virtualRows') virtualRows: VirtualScroller;

  rangeBodyYears: number[];
  minBodyDateYear: number;
  maxBodyDateYear: number;
  currentMinBodyDateYear: number;
  currentMaxBodyDateYear: number;

  selectedBodyAppointmentModes: BodyAppointmentMode[];

  isExpanded: boolean = false;
  buttonLabel: string = SEE_MORE;

  searchTitle$: Observable<SearchTitle>;
  titleCount$: Observable<string>;
  profileRows$: Observable<ProfileRow[]>;
  availableBodyAppointmentModes$: Observable<BodyAppointmentMode[]>;
  private filterDates$ = new Subject<{ min: number; max: number }>();

  constructor(private store: Store<any>) {
    super();
  }

  trackByRow = (index, row: ProfileRow) => row.metadata.profileId;
  trackByMode = (index, mode: BodyAppointmentMode) => mode.code;

  ngOnInit(): void {
    this.searchTitle$ = this.store.pipe(
      select(selectSearchTitle),
      takeUntil(this.destroyed$)
    );
    this.titleCount$ = this.store.pipe(
      select(selectSearchTitleCount),
      takeUntil(this.destroyed$)
    );
    this.profileRows$ = this.store.pipe(
      select(selectSearchRequestedProfileRows),
      takeUntil(this.destroyed$)
    );
    this.store
      .pipe(select(selectMinBodyDateYear), takeUntil(this.destroyed$))
      .subscribe((minBodyDateYear) => {
        this.minBodyDateYear = minBodyDateYear;
      });
    this.store
      .pipe(select(selectMaxBodyDateYear), takeUntil(this.destroyed$))
      .subscribe((maxBodyDateYear) => {
        this.maxBodyDateYear = maxBodyDateYear;
      });
    this.store
      .pipe(select(selectCurrentMinBodyDateYear), takeUntil(this.destroyed$))
      .subscribe((currentMinBodyDateYear) => {
        this.currentMinBodyDateYear = currentMinBodyDateYear;
      });
    this.store
      .pipe(select(selectCurrentMaxBodyDateYear), takeUntil(this.destroyed$))
      .subscribe((currentMaxBodyDateYear) => {
        this.currentMaxBodyDateYear = currentMaxBodyDateYear;
      });
    this.store
      .pipe(select(selectCurrentRangeBodyDateYears), takeUntil(this.destroyed$))
      .subscribe((rangeBodyYears) => (this.rangeBodyYears = rangeBodyYears));

    this.filterDates$.pipe(debounceTime(UI_DEBOUNCE_MS)).subscribe((range) =>
      this.store.dispatch(
        onFilterProfileRowsByDateYears({
          minDate: range.min,
          maxDate: range.max,
        })
      )
    );

    this.availableBodyAppointmentModes$ = this.store.pipe(
      select(selectSearchBodyAppointmentModes),
      takeUntil(this.destroyed$)
    );
  }

  ngAfterViewInit(): void {
    this.store.dispatch(scrollToRowIndex());
    this.store
      .pipe(select(selectScrollIndex), debounceTime(UI_DEBOUNCE_MS))
      .subscribe((index) => this.virtualRows.scrollToIndex(index, 'smooth'));
  }

  onFilterProfileRowsByAppointmentYears($event) {
    this.currentMinBodyDateYear = $event.values[0];
    this.currentMaxBodyDateYear = $event.values[1];
    this.filterDates$.next({ min: $event.values[0], max: $event.values[1] });
  }

  onFilterProfileRowsByAppointmentModes() {
    this.store.dispatch(
      onFilterProfileRowByAppointmentModes({
        selectedAppointmentModes: this.selectedBodyAppointmentModes,
      })
    );
  }

  isAreaExpanded() {
    return this.isExpanded;
  }

  toggleExpandedStatus() {
    this.isExpanded = !this.isExpanded;
    this.buttonLabel = this.isExpanded ? SEE_LESS : SEE_MORE;
  }

  onOpenedSearch() {
    this.store.dispatch(onEditSearchSidebar());
  }

  onSelectedProfile(profileId: number) {
    this.store.dispatch(onSearchSelectedProfileId({ profileId }));
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.store.dispatch(resetSearchResultPageState());
  }
}

export class SearchTitle {
  title: string;
  items?: string = null;
  allMagistrates?: boolean = false;
}
