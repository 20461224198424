import { Actions, createEffect, Effect, ofType } from '@ngrx/effects';
import {
  selectExternalJudges,
  selectOrganization,
  startNavbarLogout,
} from './navbar.action';
import { map, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { organizationSelected } from '../../_store/navbar-page.action';
import {
  logout,
  matomoTrackEvent,
  redirectAction,
} from '../../../_store/ama.actions';
import { Store } from '@ngrx/store';
import { NAVBAR_TRACK_EVENT } from '../navbar.strings';

@Injectable()
export class NavbarEffects {
  @Effect() getProfileByOrganization$ = this.actions$.pipe(
    ofType(selectOrganization),
    map((action) =>
      organizationSelected({ organization: action.organization })
    ),
    tap(() => this.store.dispatch(redirectAction({ url: 'book' })))
  );

  navTrackEventOrg$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(selectOrganization),
        tap((action) =>
          this.store.dispatch(
            matomoTrackEvent({
              category: NAVBAR_TRACK_EVENT.CATEGORY,
              action: NAVBAR_TRACK_EVENT.SELECT_ACTION,
              name: action.organization.shortLabel,
            })
          )
        )
      ),
    { dispatch: false }
  );

  navTrackEventExternal$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(selectExternalJudges),
        tap(() =>
          this.store.dispatch(
            matomoTrackEvent({
              category: NAVBAR_TRACK_EVENT.CATEGORY,
              action: NAVBAR_TRACK_EVENT.SELECT_ACTION,
              name: 'DÉTACHEMENTS',
            })
          )
        )
      ),
    { dispatch: false }
  );

  navTrackEventLogout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(logout),
        tap(() =>
          this.store.dispatch(
            matomoTrackEvent({
              category: NAVBAR_TRACK_EVENT.CATEGORY,
              action: NAVBAR_TRACK_EVENT.LOGOUT_ACTION,
            })
          )
        )
      ),
    { dispatch: false }
  );

  @Effect() startNavbarLogout = this.actions$.pipe(
    ofType(startNavbarLogout),
    map(() => logout())
  );

  constructor(private actions$: Actions, private store: Store<any>) {}
}
