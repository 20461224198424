<p-sidebar
  (visibleChange)="onCloseSidebar()"
  [visible]="isOpen$ | async"
  [draggable]="false"
  [modal]="true"
  appendTo="body"
  position="top"
>
  <div class="search-sidebar">
    <div class="row justify-content-between">
      <div class="col-11 no-gutters">
        <h4 class="ama-title ml-3 mt-3 col-4">
          Rechercher dans tous les ressorts
        </h4>
      </div>
      <div class="col-1 no-gutters">
        <button
          (click)="onCloseSidebar()"
          class="ama-button ama-button_icon float-right"
        >
          <mat-icon svgIcon="close">Fermer</mat-icon>
        </button>
      </div>
    </div>
    <ama-search></ama-search>
  </div>
</p-sidebar>
