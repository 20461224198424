import { createSelector } from '@ngrx/store';
import {
  selectAllParentOrganizations,
  selectGrades,
  selectJobs,
} from '../../../_store/navbar-page.selectors';
import { SelectOption } from '../../../../_shared/service/widget/select-option/select-option';
import { Organization } from '../../organization/organization';
import { getJobLabel } from '../../job/job.functions';
import { PLACE_HOLDER } from '../search-sidebar.strings';
import { GRADE_TO_FILTER_CODE } from '../../grade/grade.strings';

export const selectJobOptions = createSelector(selectJobs, (jobs) =>
  [getDefaultSelectOption()].concat(
    jobs.map(
      (job) =>
        new SelectOption(job.code, getJobLabel(job.longLabel), false, false)
    )
  )
);

export const selectOrganizationOptions = createSelector(
  selectAllParentOrganizations,
  (parentOrganizations: Organization[]) =>
    [getDefaultSelectOption()].concat(
      parentOrganizations.map(
        (organization) =>
          new SelectOption(
            organization.code,
            organization.shortLabel,
            false,
            false
          )
      )
    )
);

export const selectGradeOptions = createSelector(selectGrades, (grades) =>
  grades
    ? [getDefaultSelectOption()].concat(
        grades
          .filter((grade) => !GRADE_TO_FILTER_CODE.includes(grade.code))
          .map(
            (grade) =>
              new SelectOption(grade.code, grade.shortLabel, false, false)
          )
      )
    : null
);

function getDefaultSelectOption(): SelectOption {
  return new SelectOption(null, PLACE_HOLDER, false, false);
}
