<div class="row home-page-stats">
  <div class="col-3">
    <div class="d-flex flex-column">
      <div class="profile-stat-top-border internal"></div>
      <div class="profile-stat">
        {{ totalProfiles$ | async }}
      </div>
      <div class="profile-stat-text">
        magistrats en juridictions
      </div>
    </div>
  </div>
  <div class="col-3">
    <div class="d-flex flex-column">
      <div class="profile-stat-top-border external"></div>
      <div class="profile-stat">
        211
      </div>
      <div class="profile-stat-text">
        magistrats en détachement
      </div>
    </div>
  </div>
  <div class="col-3 align-self-end">
    <div (click)="onSearchAllMagistrates()" class="see-all-link">
      <span class="mr-2">Voir tous les magistrats en juridictions</span>
      <mat-icon
        class="ama-icon ama-icon-small"
        svgIcon="greater_than"
      ></mat-icon>
    </div>
  </div>
</div>
