import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';

import { Authority } from './authorities.enum';
import { StateStorageService } from './state-storage.service';
import { AccountService } from '../../core/auth/account.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UserRouteAccessService implements CanActivate {
  constructor(
    private router: Router,
    private accountService: AccountService,
    private stateStorageService: StateStorageService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    const authorities = route.data['authorities'];
    // We need to call the checkLogin / and so the accountService.identity() function, to ensure,
    // that the client has a principal too, if they already logged in by the server.
    // This could happen on a page refresh.
    return this.checkLogin(authorities, state.url);
  }

  private checkLogin(
    authorities: Authority[],
    url: string
  ): Observable<boolean> {
    return this.accountService.identity().pipe(
      map((account) => {
        if (
          authorities.length === 0 &&
          account &&
          !account.authorities.includes(Authority.ROLE_ANONYMOUS)
        ) {
          this.router.navigate(['accessdenied']);
          return false;
        }
        if (!authorities || authorities.length === 0) {
          return true;
        }

        if (
          account &&
          !account.authorities.includes(Authority.ROLE_ANONYMOUS)
        ) {
          const hasAnyAuthority = this.accountService.hasAnyAuthority(
            authorities
          );
          if (hasAnyAuthority) {
            return true;
          }
          this.router.navigate(['accessdenied']);
          return false;
        }
        this.stateStorageService.storeUrl(url);
        this.router.navigate(['login']);
        return false;
      })
    );
  }
}
