import { NavbarPanel } from './navbar-panel';
import { Organization } from '../_shared/organization/organization';
import { NAVBAR } from './navbar.strings';

export function getNavbarPanels(organizations: Organization[]): NavbarPanel[] {
  return organizations
    .slice()
    .reduce((groups: Organization[][], org: Organization) => {
      let group = groups.find((g) => shouldGroup(g[0], org));
      if (!group) {
        group = [];
        groups.push(group);
      }
      group.push(org);
      return groups;
    }, [])
    .map((group) => createNavbarPanel(group));
}

function shouldGroup(org1: Organization, org2: Organization): boolean {
  return org1.code.slice(0, 4) === org2.code.slice(0, 4);
}

function createNavbarPanel(
  singleGroupOrganizations: Organization[]
): NavbarPanel {
  const title =
    NAVBAR.TITLE_TEXT[singleGroupOrganizations[0].shortLabel.split(' ')[0]];
  return {
    title: title ?? singleGroupOrganizations[0].shortLabel,
    organizations:
      singleGroupOrganizations.length > 1
        ? singleGroupOrganizations.sort((a, b) =>
            a.shortLabel.localeCompare(b.shortLabel)
          )
        : singleGroupOrganizations,
  };
}
