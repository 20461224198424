import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProfileSheetCareerModule } from './profile-sheet/career/profile-sheet-career.module';
import { SearchModule } from './search/search.module';

@NgModule({
  declarations: [],
  imports: [CommonModule, ProfileSheetCareerModule, SearchModule],
})
export class SharedModule {}
