import { Action, createReducer, on } from '@ngrx/store';
import { BookPageStates, initialBookPageStates } from './book-page.states';
import {
  restoreBookPageState,
  scrollBookPageJobCode,
  startLoading,
  stopLoading,
} from './book-page.action';

const reducer = createReducer(
  initialBookPageStates,
  on(restoreBookPageState, () => initialBookPageStates),
  on(scrollBookPageJobCode, (state, action) => ({
    ...state,
    activeJobCode: action.code,
  })),
  on(startLoading, (state) => ({
    ...state,
    loading: true,
  })),
  on(stopLoading, (state) => ({
    ...state,
    loading: false,
  }))
);

export function BookPageReducer(
  state: BookPageStates | undefined,
  action: Action
) {
  return reducer(state, action);
}
