import { Profile } from '../../../_shared/profile/profile';
import { ProfileCard } from './profile-card';
import { PROFILE_CARD } from './profile-card.strings';
import { LocalDate } from '../../../../_shared/domain/local-date';
import { AVATAR_PATH } from '../../../_shared/profile/profile.strings';
import { Grade } from '../../../_shared/code-management/code-management';

export function getProfileCards(
  profiles: Profile[],
  allGrades: Grade[]
): ProfileCard[] {
  return profiles
    .slice()
    .reduce((profileCards: ProfileCard[], profile: Profile) => {
      profileCards.push({
        header: getHeader(profile),
        subHeader: getSubHeader(profile, allGrades),
        picturePath: AVATAR_PATH.concat(profile.pictureName),
        email: profile.email?.toLocaleLowerCase(),
        businessPhone: profile.businessPhone,
        metadata: {
          profileId: profile.id,
        },
        style: { width: '307px', height: '288px', marginRight: '20px' },
      });
      return profileCards;
    }, [])
    .sort((a, b) => a.header.localeCompare(b.header));
}

function getHeader(profile: Profile): string {
  return PROFILE_CARD.HEADER.LABEL.replace(
    '{{lastName}}',
    profile.lastName.toUpperCase()
  ).replace('{{firstName}}', profile.firstName);
}

function getSubHeader(profile: Profile, allGrades: Grade[]): string {
  return PROFILE_CARD.SUBHEADER.GRADE.replace(
    '{{grade}}',
    allGrades.find((g) => g.code === profile.gradeCode)?.shortLabel
  ).concat(
    PROFILE_CARD.SUBHEADER.GRADE_DATE.replace(
      '{{gradeAppointmentDate}}',
      profile.gradeDate
        ? ' | '.concat(new LocalDate(profile.gradeDate).getDayMonthYear())
        : ''
    )
  );
}
