import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { SearchResultsPageEffects } from './search-results-page.effects';
import { StoreModule } from '@ngrx/store';
import { SearchResultsPageReducer } from './search-results-page.reducer';

@NgModule({
  imports: [
    EffectsModule.forFeature([SearchResultsPageEffects]),
    StoreModule.forFeature('SEARCH-RESULTS', SearchResultsPageReducer),
  ],
})
export class SearchResultsPageStoreModule {}
