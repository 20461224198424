import { Routes } from '@angular/router';
import { UserRouteAccessService } from '../../_shared/service/security/user-route-access.service';
import { Authority } from '../../_shared/service/security/authorities.enum';
import { ProfileSheetPageComponent } from './profile-sheet-page.component';

export const profileSheetPageRoutes: Routes = [
  {
    path: 'sheet',
    data: {
      authorities: [Authority.ROLE_USER],
    },
    canActivate: [UserRouteAccessService],
    children: [
      {
        path: '',
        component: ProfileSheetPageComponent,
      },
    ],
  },
];
