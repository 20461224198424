export const ORGANIZATION_LABEL_STR = {
  DE: 'de',
  D_QUOTATION_MARK: "d'",
  ORGANIZATION_WITHOUT_DE_ADDITIONAL: '{{shortOrg}} {{orgName}} {{additional}}',
  ORGANIZATION_WITH_D_QUOTATION_MARK_ADDITIONAL:
    '{{shortOrg}} {{orgName}} {{additional}}',
  ORGANIZATION_WITH_DE: '{{shortOrg}} {{orgName}}',
};

export const ORGANIZATION_SHORT_LABEL = {
  'TA Orleans': 'TA Orléans',
  'TA Cergy-pontoise': 'TA Cergy-Pontoise',
  'TA Clermont-ferrand': 'TA Clermont-Ferrand',
  'TA Châlons': 'TA Châlons-en-Champagne',
  'TA Guadeloupe - sb-sm': 'TA Guadeloupe, St-M. et St-B.',
  'TA Martinique - spm': 'TA Martinique et St-P. et M.',
  'TA Polynesie française': 'TA Polynésie française',
  'TA Nelle- caledonie - wf': 'TA Nouvelle-Calédonie et W. et F.',
  'TA Réunion - mayotte': 'TA Réunion et Mayotte',
  'TA Nimes': 'TA Nîmes',
  GÉNÉRIQUE: 'CFJA',
};

export const ORGANIZATION_LONG_LABEL = {
  "UO générique Conseil d'Etat":
    'Centre de formation de la juridiction administrative',
};

export const ORGANIZATION = {
  COLORS: {
    TA: {
      textColor: '#3F7FE6',
      backgroundColor: '#F2F6FF',
    },
    CAA: {
      textColor: '#E95303',
      backgroundColor: '#fdefe8',
    },
    CNDA: {
      textColor: '#00834B',
      backgroundColor: '#DEF8ED',
    },
    CCSP: {
      textColor: '#fe2512',
      backgroundColor: '#fae1df',
    },
    CFJA: {
      textColor: '#f8902d',
      backgroundColor: '#f8ece0',
    },
    OTHER: {
      textColor: '#00834B',
      backgroundColor: '#DEF8ED',
    },
  },
};
